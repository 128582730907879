<div class="trf_form_two_mainn">
  <!-- href="/trf" -->
<a class="gs-back-head-d col-md-12 p-0 pointer" (click)="redirectToListing()"><span
    class="mb-3 figma_blue_color"><i class="icon-angle-down angle-rotate-d"></i>Back to JRF</span></a>

    <div class="d-flex row trf_1024" style="row-gap: 12px;">
  <div class="trf_side__navv col-lg-2 col-4 pl-0">
    <aside class="js-side-filter sticky-left trf__sticky">
      <div class="gs-side-filter gs-side-filter--sm contenT_Main trf_form__aside">
        <ul class="gs-side-filter__list gs-long-list d-block" id="progressbar">
          <li class="gs-side-filter__list__item active gs-long-list__item" style="cursor: pointer; width: fit-content"
            (click)="respectiveScreen('basicinfo')" [ngClass]="{ check: rafBasicCheck, active: rafBasicActive }">
            <div class="gs-side-filter__link gs-side-filter__link--sm new__side__filter">
              <span class="new__side__Label">Basic Info</span>
            </div>
          </li>
          <li class="gs-side-filter__list__item active gs-long-list__item" style="cursor: pointer; width: fit-content"
            (click)="respectiveScreen('description')" [ngClass]="{check: rafDescCheck, active: rafDescActive}">
            <div class="gs-side-filter__link gs-side-filter__link--sm">
              <span class="new__side__Label">Job Description</span>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  </div>
  <div class="col-lg-12 col-xl-10 col-12 raf__twoo__mainn_part background__wrapper">
    <form novalidate #basicInfo="ngForm">

      <div *ngIf="rafJobTitle">
        <div class="raf_Two_job__title_main">
          <div class="d-flex align-items-baseline">
            <h1 class="head-h1 px-0 col-md-8"> Job Requisition Form </h1>
            <!-- <p class="figma__label px-0 col-md-4 pl-3 figma_blue_color"> Total Budgeted : </p> -->

          </div>
          <div class="row mt-3">
            <div class="col-md-8 figma__ng__select">
              <label for="icon" class="figma__label my-3">Requester<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="trfrequesterArray" (change)="GetBrandDetailsByUserId($event)"
                bindLabel="userName" bindValue="userId" placeholder="Select requester"
                [(ngModel)]="talentRequisition.requester" name="requester" #requester="ngModel"
                [disabled]="viewDisable?'true':'false'  && disableEdit" required>
              </ng-select>
              <div *ngIf="(requester.touched || basicInfo.submitted) &&requester.errors?.required">
                <span class="errorMessage">
                  Please select requester
                </span>
              </div>
            </div>



            <div class="col-md-4 figma__ng__select">
              <!-- <label for="icon" class="figma__label my-3">Priority<span class="mandiatory">*</span> </label>
              <div class="col-md-12 d-flex yes__No__radios yes__No__radios_Two pl-0">
                <p class="d-flex">
                  <input class="pointer input__Accent" type="radio" id="declare1" name="declareOne" value="Highpriority"
                    [(ngModel)]="talentRequisition.priority">
                  <label for="declare1" class="figma__label pl-2 pt-1">High Priority</label>
                </p>
                <p class="ml-5 d-flex">
                  <input class="pointer input__Accent" type="radio" id="declare2" name="declareOne" value="Normalpriority"
                    [(ngModel)]="talentRequisition.priority">
                  <label for="declare2" class="figma__label pl-2 pt-1">Medium Priority</label>
                </p>
              </div> -->
            </div>

            <div class="col-md-12">
              <label class="figma__label my-5"> Raising Job Requisition for ? </label>
            </div>

            <!-- <div class="col-md-4 figma__ng__select">
              <label for="icon" class="figma__label my-3">Job Title<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="hierarchy_job" bindLabel="title" bindValue="jobApprovalHierarchyId" required
                [(ngModel)]="talentRequisition.jahJobTitleId" name="jobTitle" #jobTitle="ngModel"
                placeholder="Select job title" [multiple]="false" (change)="jobTrf($event)">
              </ng-select>
              <div *ngIf="(jobTitle.touched || basicInfo.submitted) &&jobTitle.errors?.required">
                <span class="errorMessage">
                  Please select job title
                </span>
              </div>
            </div> -->
            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Brand<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="brandsnewarray" bindLabel="brandName" bindValue="brandId"
                placeholder="Select Brand" [(ngModel)]="talentRequisition.brand" name="brand" #brand="ngModel" required
                [multiple]="false" (change)="getcountries(talentRequisition.brand)"
                [disabled]="viewDisable?'true':'false' && disableEdit">
              </ng-select>
              <!-- <input type="text" class="col-md-12 figma__Inputs px-0 trf_form_input dependent_color_field" placeholder="Brand" [(ngModel)]="talentRequisition.brand"
                name="brand" #brand="ngModel" readonly /> -->
              <!-- <ng-select class="" [items]="addTalentReqArray" bindLabel="name" bindValue="brandId"
                placeholder="Select brand" [(ngModel)]="talentRequisition.brand" name="brand" #brand="ngModel" required>
              </ng-select> -->

              <div *ngIf="(brand.touched || basicInfo.submitted) &&brand.errors?.required">
                <span class="errorMessage">
                  Please select brand
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Country<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="trf_Country" bindLabel="countryName" bindValue="countryId" required
                [disabled]="viewDisable?'true':'false' && disableEdit" [(ngModel)]="talentRequisition.country" name="country"
                #country="ngModel" placeholder="Select country" (change)="getCampus($event.countryId)">
              </ng-select>
              <!-- (change)="trfCountry($event)" -->
              <div *ngIf="(country.touched || basicInfo.submitted) &&country.errors?.required">
                <span class="errorMessage">
                  Please select country
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Campus<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="trfCampus" bindLabel="name" bindValue="campusId"
                [(ngModel)]="talentRequisition.campus" name="campus" #campus="ngModel" required
                placeholder="Select campus" [multiple]="true" (click)="campusDetails($event)"
                [disabled]="viewDisable?'true':'false' && disableEdit">
                <!-- (change)="campusDetails($event)" -->
              </ng-select>
              <div *ngIf="(campus.touched || basicInfo.submitted) &&campus.errors?.required">
                <span class="errorMessage">
                  Please select campus
                </span>
              </div>
            </div>

            <div class="col-lg-8 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Job Title<span class="mandiatory">*</span></label>
              <ng-select class="" [items]="hierarchy_job" bindLabel="jobTitleName" bindValue="jahId" required
                [(ngModel)]="talentRequisition.jahJobTitleId" name="jobTitle" #jobTitle="ngModel"
                [disabled]="viewDisable?'true':'false' && disableEdit" placeholder="Select job title" [multiple]="false"
                (change)="validateJobTitle($event)">
              </ng-select>
              <div *ngIf="(jobTitle.touched || basicInfo.submitted) &&jobTitle.errors?.required">
                <span class="errorMessage">
                  Please select job title
                </span>
              </div>
              <span class="errorMessage">{{errorjob}}</span>
            </div>

            <div class="col-lg-4 col-6 figma__ng__select mt-1">
              <label for="icon" class="figma__label my-3">Priority </label>
              <div class="col-md-12 d-flex yes__No__radios yes__No__radios_Two pl-0">
                <p class="d-flex align-items-center">
                  <input class="pointer input__Accent" type="radio" id="declare1" name="declareOne" value="Highpriority"
                    [(ngModel)]="talentRequisition.priority" [disabled]="viewDisable?'true':'false'"
                    #declareOne="ngModel">
                  <label for="declare1" class="figma__label pl-2 pt-1">High Priority</label>
                </p>
                <p class="ml-5 d-flex align-items-center">
                  <input class="pointer input__Accent" type="radio" id="declare2" name="declareOne"
                    value="Normalpriority" [(ngModel)]="talentRequisition.priority"
                    [disabled]="viewDisable?'true':'false'" #declareOne="ngModel">
                  <label for="declare2" class="figma__label pl-2 pt-1">Normal Priority</label>
                </p>
              </div>
              <div *ngIf="(declareOne.touched || basicInfo.submitted) && declareOne.errors?.required">
                <span class="errorMessage">
                  Please select priority
                </span>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">
                Type of Appointment<span class="mandiatory">*</span>
              </label>
              <ng-select
                class="" (change)="typeOfappointment($event)"
                [items]="Appointment_data"
                bindLabel="appointment_Name"
                bindValue="appointment_Id"
                placeholder="Select type of appointment"
                [(ngModel)]="talentRequisition.typesOfAppointment"
                name="typeOfAppointmenttt"
                #typeOfAppointmenttt="ngModel"
                required
                [disabled]="viewDisable ? 'true' : 'false'"
              >
              </ng-select>
              <div
                *ngIf="
                  (typeOfAppointmenttt.touched || basicInfo.submitted) &&
                  typeOfAppointmenttt.errors?.required
                "
              >
                <span class="errorMessage">
                  Please select type of appointment
                </span>
              </div>
            </div>

            <div class="col-md-6 col-xl-6"></div>

            <div class="col-md-12" *ngIf="disableemployee">
              <label for="icon" class="figma__label my-3"
              >Add Employee Details <span class="mandiatory">*</span></label
            >
            <textarea class="figma__textArea w-100" [(ngModel)]="talentRequisition.employeeDetails" required
            name="employeeDetails" #employeeDetails="ngModel" [disabled]="viewDisable?'true':'false'">
          </textarea>
          <div
                *ngIf="
                  (employeeDetails.touched || basicInfo.submitted) &&
                  employeeDetails.errors?.required
                "
              >
                <span class="errorMessage">
                  Please enter employee details
                </span>
              </div>

            </div>

            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Category <span class="mandiatory">*</span></label>
              <ng-select class="" [items]="categories" bindLabel="jobCategoryName" bindValue="jobCategoryId" required
                [(ngModel)]="talentRequisition.category" name="Caategory" #Caategory="ngModel"
                [disabled]="viewDisable?'true':'false'" placeholder="Select category">
              </ng-select>
              <div *ngIf="(Caategory.touched || basicInfo.submitted) &&Caategory.errors?.required">
                <span class="errorMessage">
                  Please select category
                </span>
              </div>
            </div>


            <div class="col-lg-4 col-6">
              <label for="icon" class="figma__label my-3">Number Of Positions<span class="mandiatory">*</span></label>
              <input type="text" class="figma__Inputs col-md-12 px-0 trf_form_input"
                placeholder="Enter number of positions" [(ngModel)]="talentRequisition.numberOfPositions"
                name="numberOfPositions" #numberOfPositions="ngModel" onkeypress="return /[0-9]/i.test(event.key)"
                required maxlength="2" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                [disabled]="viewDisable?'true':'false'" />
              <div *ngIf="(numberOfPositions.touched || basicInfo.submitted) &&numberOfPositions.errors?.required">
                <span class="errorMessage">
                  Please enter number of positions
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3">Budgeted / Non-budgeted<span
                  class="mandiatory">*</span></label>
              <ng-select class="" [items]="hyring_Type" bindLabel="hiring_Name" bindValue="hiring_Id"
                placeholder="Select budgeted / non-budgeted" [multiple]="false"
                [(ngModel)]="talentRequisition.budOrNonBud" name="budOrNonBud" #budOrNonBud="ngModel" required
                [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div *ngIf="(budOrNonBud.touched || basicInfo.submitted) &&budOrNonBud.errors?.required">
                <span class="errorMessage">
                  Please select budgeted / non-budgeted
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-6 figma__ng__select">
              <label for="icon" class="figma__label my-3"> Type of Employment<span class="mandiatory">*</span> </label>
              <ng-select class="" [items]="employement_details" bindLabel="employment_Name" bindValue="employment_Id"
                placeholder="Select type of employment" [(ngModel)]="talentRequisition.typeOfEmployment"
                name="typeOfEmployment" #typeOfEmployment="ngModel" required [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div *ngIf="(typeOfEmployment.touched || basicInfo.submitted) &&typeOfEmployment.errors?.required">
                <span class="errorMessage">
                  Please select type of employment
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-6 figma__ng__select new-trf-select">
              <label for="icon" class="figma__label my-3">Offered Salary Range (Annually)<span
                  class="mandiatory">*</span></label>
              <div class="d-flex align-items-center">
                <!-- <input type="text" class="figma__Inputs px-0 trf_form_input input__curr" placeholder="INR"
                  style="border: 1px solid #764fdb !important;" name="ctcCode" #ctcCode="ngModel"
                  [(ngModel)]="talentRequisition.ctcCode" readonly /> -->

                  <ng-select class="pe-0" [items]="newCurrenyCodeArray" #expectedSALary="ngModel" name="ctcCode"
                  placeholder="" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="talentRequisition.ctcCode"
                 (change)="clearSalaryinputFields()" required>
                </ng-select>

                <input style="width:100% !important;" type="text"
                  class="figma__Inputs px-0 trf_form_input ml-1"
                  onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
                  [(ngModel)]="talentRequisition.offerSalaryRangeFrom" name="offerSalaryRangeFrom" (keyup)="minSalaryCountryValidation()"
                  #offerSalaryRangeFrom="ngModel" required [disabled]="viewDisable?'true':'false'" appDigitDecimal />
                <span class="figma__label mx-1"> To </span>
                <input style="width:100% !important; border-radius: 8px !important;" type="text"
                  class="figma__Inputs px-0 trf_form_input" (keyup)="validSalaryrange($event) || getValueTwo()"
                  onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
                  [(ngModel)]="talentRequisition.offerSalaryRangeTo" name="offerSalaryRangeTo"
                  #offerSalaryRangeTo="ngModel" required [disabled]="viewDisable?'true':'false'" appDigitDecimal />
              </div>
              <div
                *ngIf="(offerSalaryRangeFrom.touched || basicInfo.submitted) &&offerSalaryRangeFrom.errors?.required">
                <span class="errorMessage">
                  Please enter offered salary range
                </span>
              </div>
              <span class="errorMessage" *ngIf="trfSalaryRangeError">
                Please enter valid salary range
              </span>
              <div *ngIf="newSalaryValidation">
                <span class="errorMessage">
                  Please enter 50000 above
                </span>
              </div>
              <div *ngIf="newSalaryValidationTwo">
                <span class="errorMessage">
                  Please enter 5000 above
                </span>
              </div>
              <!-- <div *ngIf="ctc.errors">
                 pattern="^[^.]*$"
                <span class="errorMessage" *ngIf="ctc.errors?.pattern">
                  Invalid ctc
                </span>
              </div> -->
            </div>
            <div class="col-lg-4 col-6 field mobile_fields_gap figma_calender_input">
              <label class="form-label-d new__input__label mt-3">Expected Joining Date<span
                  class="mandiatory">*</span></label>
              <p-calendar class="position-relative" name="expectedJoiningDate" placeholder="Select date"
                [style]="{ width: '100%' }" readonlyInput="readonlyInput" [minDate]="minStartDate"
                [inputStyle]="{ width: '100%' }" [disabled]="viewDisable ? 'disabled' : null"
                [(ngModel)]="talentRequisition.expectedJoiningDate" dateFormat="dd/mm/yy"
                [ngModelOptions]="{ standalone: true }" #expectedJoiningDate="ngModel" inputId="icon" required
                [showIcon]="true"></p-calendar>
              <!-- <img class="position-absolute calender__icon"
                src="{{appSettings.imageUrl}}/img/calendar-figma-icon.svg" alt="calender" /> -->
              <div *ngIf="(expectedJoiningDate.touched || basicInfo.submitted) && expectedJoiningDate.errors?.required">
                <span class="errorMessage"> Please select date </span>
              </div>
            </div>

            <div class="col-lg-4 col-6 mt-1 mobile_fields_gap figma__ng__select mt-2">
              <label class="form-label-d mt-1 new__input__label mb-1">Total Experience(in years) <span
                  class="mandiatory">*</span> </label>
              <div class="d-flex">
                <div class="col-md-6 px-0">
                  <ng-select class="custom" [items]="fromyears" bindLabel="name" bindValue="name" placeholder=""
                    name="id" (change)="totoalExperienc($event, talentRequisition)"
                    [disabled]="viewDisable?'true':'false'" [(ngModel)]="talentRequisition.totalExperienceFrom"
                    #totalExperienceFrom="ngModel" name="totalExperienceFrom" style="width: 96% !important" required>
                  </ng-select>
                  <div
                    *ngIf="(totalExperienceFrom.touched || basicInfo.submitted) && totalExperienceFrom.errors?.required">
                    <span class="errorMessage">
                      Please select experience
                    </span>
                  </div>
                </div>
                <span class="form-label-d new__from__to mx-1 mt-2"> To </span>
                <div class="col-md-6 pl-0">
                  <ng-select class="custom" [items]="toyears" bindLabel="name" bindValue="name" placeholder="" name="id"
                    [(ngModel)]="talentRequisition.totalExperienceTo" name="totalExperienceTo"
                    #totalExperienceTo="ngModel" style="width: 96% !important" required
                    [disabled]="viewDisable?'true':'false'">
                  </ng-select>
                  <div class="width-max"
                    *ngIf="(totalExperienceTo.touched || basicInfo.submitted) && totalExperienceTo.errors?.required">
                    <span class="errorMessage">
                      Please select experience
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="d-flex">
                <div *ngIf="(totalExperienceFrom.touched || basicInfo.submitted) && totalExperienceFrom.errors?.required">
                  <span class="errorMessage">
                    Please select experience
                  </span>
                </div>

                <div
                *ngIf="(totalExperienceTo.touched || basicInfo.submitted) && totalExperienceTo.errors?.required">
                <span class="errorMessage" style="margin-left: 38px">
                  Please select experience
                </span>
              </div>
              </div> -->
            </div>

            <!-- Higher level of education -->
            <div class="col-lg-4 col-6 field three__parts__margin mobile_fields_gap figma__ng__select mt-3">
              <label class="form-label-d new__input__label">Higher Level of Education</label>

              <ng-select [items]="Education_details" #higherLevelOfEducation="ngModel" name="higherLevelOfEducation"
                placeholder="Select education" bindLabel="educationName" bindValue="educationId"
                [(ngModel)]="talentRequisition.higherLevelOfEducation" [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div *ngIf="
                  (higherLevelOfEducation.touched ||
                    basicInfo.submitted) &&
                  higherLevelOfEducation.errors?.required
                ">
                <span class="errorMessage">
                  Please select higher level education
                </span>
              </div>
            </div>
            <!-- Higher level of education -->

            <!-- qualifications -->
            <div
              class="col-lg-4 col-6 field qualifications__part three__parts__margin mobile_fields_gap figma__ng__select mt-3">
              <label class="form-label-d new__input__label">Qualifications</label>

              <ng-select [items]="degree" #qualification="ngModel" name="qualification"
                placeholder="Select qualifications" bindLabel="degreeName" bindValue="degreeId" [multiple]="true"
                [(ngModel)]="talentRequisition.qualification" [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div *ngIf="(qualification.touched || basicInfo.submitted) &&qualification.errors?.required">
                <span class="errorMessage">
                  Please select qualifiCations
                </span>
              </div>
            </div>
            <!-- qualifications -->

            <!-- <div class="col-md-4">
              <label for="icon" class="figma__label my-3">Total Incurred Cost<span class="mandiatory">*</span></label>
              <div class="d-flex">
                <input type="text" class="figma__Inputs px-0 trf_form_input input__curr" placeholder="INR"
                  style="border: 1px solid #764fdb !important;" name="totalIncurredCostCode"
                  #totalIncurredCostCode="ngModel" [(ngModel)]="talentRequisition.totalIncurredCostCode" readonly />
                <input style="width:100% !important; border-radius: 0 8px 8px 0 !important;" type="text"
                  class="figma__Inputs px-0 trf_form_input" placeholder="Total incurred cost"
                  onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
                  [(ngModel)]="talentRequisition.totalIncurredCost" name="totalIncurredCost"
                  #totalIncurredCost="ngModel" required [disabled]="viewDisable?'true':'false'" appDigitDecimal/>
              </div>
              <div *ngIf="(totalIncurredCost.touched || basicInfo.submitted) &&totalIncurredCost.errors?.required">
                <span class="errorMessage">
                  Please enter total incurred cost
                </span>
              </div>
            </div> -->
            <!-- <div class="col-md-4 figma__ng__select"> -->
            <!-- <label for="icon" class="figma__label my-3">Approved By <span class="mandiatory">*</span></label>
              <ng-select class="" [items]="trfApprovedBy" bindLabel="name" bindValue="id" required
                [(ngModel)]="talentRequisition.approvedBy" name="approvedBy" #approvedBy="ngModel"
                placeholder="Select approved by">
              </ng-select>
              <div *ngIf="(approvedBy.touched || basicInfo.submitted) &&approvedBy.errors?.required">
                <span class="errorMessage">
                  Please select approved by
                </span>
              </div>
            </div> -->


          </div>
        </div>

        <div class="col-md-12 px-0 mt-4">
          <!-- [disabled]="errorJobTitleDisabled || trfSalaryRangeError || newSalaryValidation || newSalaryValidationTwo" -->
          <button type="button" class="blue-btn" style="float: right;" (click)="trfNextScreen()"
            *ngIf="trfNormalButtons" >
            <!-- && jobRes===false -->
            Job Description
          </button>
          <button type="button" class="blue-btn" style="float: right;" (click)="trfNextScreen();scrollToTop()"
            *ngIf="trfViewbuttons">
            Next
          </button>
        </div>


      </div>
    </form>
    <form novalidate #descriptionForm="ngForm">
      <div class="loader" *ngIf="showLoader">
        <img src="{{this.appSettings.imageUrl}}/img/NEW-GSF_logo_whtext_085d6ff136.png" alt="loader">
      </div>
      <div *ngIf="rafJobDescription">
        <div class="raf__Two_descriptionn_main background__wrapper row" *ngIf="!trfTwoSubmitDisabled">
          <div class="col-md-12" *ngIf="trfNormalButtons">
            <label for="icon" class="figma__label my-3">Overview <span class="mandiatory">*</span></label>
            <p-editor name="classic-editor" [(ngModel)]="trfJobDescription.overview" name="overview"
              #overview="ngModel" required>
            </p-editor>
            <div *ngIf="(overview.touched || descriptionForm.submitted) &&overview.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div>


          <div class="col-md-12" *ngIf="trfViewbuttons">
            <label for="icon" class="figma__label my-3">Overview <span class="mandiatory">*</span></label>

            <p class="view-view" [innerHTML]="trfJobDescription.overview"> </p>

            <!-- <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.overview"
              name="overview" #overview="ngModel" [disabled]="viewDisable?'true':'false'">
            </textarea>
            <div *ngIf="(overview.touched || descriptionForm.submitted) &&overview.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div> -->
          </div>
          <div class="col-md-12" *ngIf="trfNormalButtons">
            <label for="icon" class="figma__label my-3">Responsibilities<span class="mandiatory">*</span> </label>
            <p-editor  name="classic-editor" [(ngModel)]="trfJobDescription.responsibilities"
              name="responsibilities" #responsibilities="ngModel" required>
            </p-editor>
            <div *ngIf="(responsibilities.touched || descriptionForm.submitted) &&responsibilities.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div>

          <div class="col-md-12" *ngIf="trfViewbuttons">
            <label for="icon" class="figma__label my-3">Responsibilities<span class="mandiatory">*</span> </label>

            <p class="view-view" [innerHTML]="trfJobDescription.responsibilities"> </p>

            <!-- <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.responsibilities"
              name="responsibilities" #responsibilities="ngModel" [disabled]="viewDisable?'true':'false'">
            </textarea>
            <div *ngIf="(responsibilities.touched || descriptionForm.submitted) &&responsibilities.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div> -->
          </div>
          <div class="col-md-12" *ngIf="trfNormalButtons">
            <label for="icon" class="figma__label my-3">Skills Description<span class="mandiatory">*</span> </label>
            <p-editor  name="classic-editor" [(ngModel)]="trfJobDescription.skillsDescription"
              name="skillsDescription" #skillsDescription="ngModel" required>
            </p-editor>
            <div *ngIf="(skillsDescription.touched || descriptionForm.submitted) &&skillsDescription.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div>

          <div class="col-md-12" *ngIf="trfViewbuttons">
            <label for="icon" class="figma__label my-3">Skills Description<span class="mandiatory">*</span> </label>

            <p class="view-view" [innerHTML]="trfJobDescription.skillsDescription"> </p>

            <!-- <p class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.skillsDescription"
              name="skillsDescription" #skillsDescription="ngModel" [disabled]="viewDisable?'true':'false'">
            </p> -->
            <!-- <div *ngIf="(skillsDescription.touched || descriptionForm.submitted) &&skillsDescription.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div> -->
          </div>

          <!-- <div class="col-md-12" *ngIf="trfNormalButtons">
            <label for="icon" class="figma__label my-3">Website Short Description<span class="mandiatory">*</span>
            </label>
            <ckeditor [editor]="Editor" name="classic-editor" required
              [(ngModel)]="trfJobDescription.websiteShortDescription" name="websiteShortDescription"
              #websiteShortDescription="ngModel">
            </ckeditor>
            <div
              *ngIf="(websiteShortDescription.touched || descriptionForm.submitted) &&websiteShortDescription.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div> -->

          <!-- <div class="col-md-12" *ngIf="trfViewbuttons">
            <label for="icon" class="figma__label my-3">Website Short Description<span class="mandiatory">*</span>
            </label>
            <textarea class="col-md-12 px-0 disabled__trf__textareas"
              [(ngModel)]="trfJobDescription.websiteShortDescription" name="websiteShortDescription"
              #websiteShortDescription="ngModel" [disabled]="viewDisable?'true':'false'">
            </textarea>
            <div
              *ngIf="(websiteShortDescription.touched || descriptionForm.submitted) &&websiteShortDescription.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div> -->

          <!-- <div class="col-md-12" *ngIf="trfNormalButtons">
            <label for="icon" class="figma__label my-3">Disclaimer<span class="mandiatory">*</span>
            </label>
            <ckeditor [editor]="Editor" name="classic-editor" required [(ngModel)]="trfJobDescription.disclaimer"
              name="websiteDisclaimer" #websiteDisclaimer="ngModel">
            </ckeditor>
            <div *ngIf="(websiteDisclaimer.touched || descriptionForm.submitted) &&websiteDisclaimer.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div> -->


          <!-- <div class="col-md-12" *ngIf="trfViewbuttons">
            <label for="icon" class="figma__label my-3">Disclaimer<span class="mandiatory">*</span>
            </label>
            <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.disclaimer"
              name="websiteDisclaimer" #websiteDisclaimer="ngModel" [disabled]="viewDisable?'true':'false'">
            </textarea>
            <div *ngIf="(websiteDisclaimer.touched || descriptionForm.submitted) &&websiteDisclaimer.errors?.required">
              <span class="errorMessage">
                Please add content
              </span>
            </div>
          </div> -->

          <div class="d-md-flex col-md-12 figma__ng__select mt-3"
            *ngFor="let item of TRFInfoData.jobskill; let i = index">
            <div class="col-md-4 field form-group-d pl-0 pr-2 mobile_skills_category mobile_width_eighty">
              <label class="form-label-d new__input__label">Skills Category<span class="mandiatory">*</span></label>

              <ng-select [items]="skill_list" bindLabel="skill_CategoryName" bindValue="skill_CategoryId" required
                [(ngModel)]="item.skillCategory" name="trfSkillsCCAtegorry{{i}}" (change)="SkillNameById($event, i)"
                #trfSkillsCCAtegorry="ngModel" placeholder="Select skills category" [multiple]="false"
                [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div
                *ngIf="(trfSkillsCCAtegorry.touched || descriptionForm.submitted) &&trfSkillsCCAtegorry.errors?.required">
                <span class="errorMessage">
                  Please select skills category
                </span>
              </div>

            </div>
            <div class="col-lg-4 col-6 field form-group-d mobile__Skills mobile_width_eighty">
              <label class="form-label-d new__input__label">Skills<span class="mandiatory">*</span></label>

              <ng-select [items]="item.skill_list" bindLabel="skillName" bindValue="skillCategoryId"
                [(ngModel)]="item.skills" name="item.trffSkiillss{{i}}" #trffSkiillss="ngModel"
                placeholder="Select skills" [multiple]="true" required [disabled]="viewDisable?'true':'false'">
              </ng-select>
              <div *ngIf="(trffSkiillss.touched || descriptionForm.submitted) &&trffSkiillss.errors?.required">
                <span class="errorMessage">
                  Please select skills
                </span>
              </div>
            </div>

            <div class="document_plus_btn" style="margin-top: 31px !important">
              <!-- <button type="button" class="plus-icon-button mr-1"
                *ngIf="((i == TRFInfoData.jobskill.length - 1) || (TRFInfoData.jobskill.length == 1 )) && (skill_list.length > 0)" (click)="addSkill()">
                <img src="{{appSettings.imageUrl}}/img/new-new-plus-icon.png" alt="pplus"
                  style="height: 15px; margin-top: -4px;">
              </button>
              <button type="button" class="new_figma_delete_red_btn btn" (click)="deleteSkill(i)"
                *ngIf="(TRFInfoData.jobskill.length === ( i+1)) &&  (i !=0 )">
                <img src="{{appSettings.imageUrl}}/img/white-delete-icon.png" alt="" />
              </button> -->
              <span role="button" class="add__Text ml-0"
                *ngIf="((i == TRFInfoData.jobskill.length - 1) || (TRFInfoData.jobskill.length == 1 )) && !trfViewbuttons"
                (click)="addSkill()"> Add More Skills </span>
              <span role="button" class="add__Text remove__Text" style="color: rgba(254, 84, 84, 1) !important;"
                (click)="deleteSkill(i)"
                *ngIf="(TRFInfoData.jobskill.length === ( i+1)) &&  (i !=0 ) && !trfViewbuttons"> Remove </span>

            </div>
          </div>
          <div class="col-md-12 mt-3 justify-content-between d-flex">
            <button type="button" class="secondary-btn" style="float: left;" (click)="respectiveScreen('basicinfo')"
              *ngIf="trfNormalButtons">
              Basic Info </button>
            <button type="button" class="secondary-btn" style="float: left;" (click)="respectiveScreen('basicinfo');scrollToTop()"
              *ngIf="trfViewbuttons">
               Back
            </button>
            <button type="button" class="figma_background_blue_btn figma_background_blue_btn_disabled"
              style="padding:12px 30px !important" (click)="createTrf()" *ngIf="!trfViewbuttons"> Submit </button>
              <!-- [disabled]="trfTwoSubmitDisabled || descriptionForm.invalid " -->
          </div>

          <!-- <div class="col-md-4 figma__ng__select">
          <label for="icon" class="figma__label my-3">Skills Category</label>
          <ng-select class="" [items]="trfSkillsCategory" bindLabel="name" bindValue="id"
            [(ngModel)]="skillCategory" name="trfSkillsCCAtegorry" placeholder="Select skills category"
            [multiple]="false">
          </ng-select>
        </div>

        <div class="col-md-4 figma__ng__select">
          <label for="icon" class="figma__label my-3">Skills</label>
          <ng-select class="" [items]="trfSkillss" bindLabel="name" bindValue="id" [(ngModel)]="skills"
            name="trffSkiillss" placeholder="Select skills" [multiple]="true">
          </ng-select>
        </div> -->

        </div>
      </div>
    </form>
  </div>
  </div>
  <!-- jobtitle popup -->
  <div *ngIf="trfJobTitleMOdal" id="addApplicantModalMain" class=" addApplicant__modal_main trfJobTitleMOdal_mainn">
    <div class="addApplicant__mod__content" style="height:auto !important">
      <div class="modal__heading d-flex align-items-baseline">
        <h5 class="add__app__heading" id="addApplicantTitle" style="margin-bottom: 30px !important;">
          Alert<span class="mandiatory">*</span>
        </h5>
        <!-- <button type="button" class="close new_figma_blue_btn figma__close relative ml-auto"
            (click)="CloseTrfJobTitlepopUP()">
            <img class="absolute" src="{{ appSettings.imageUrl }}/img/blue-close-icon.svg" alt="close" />
          </button> -->
      </div>
      <div>
        Cannot add <span class="fw600">{{titleError}} </span> for <span class="fw600">{{popupError}} </span> Campus.
        Please contact HR team and provide ERROR CODE-ER401”
        <!-- <span class="fw600">{{titleError}} </span> for <span class="fw600">{{popupError}} </span>Campus doesn't have the Approval Hierarchy set . Please contact HR team to add the same.and provide ERROR CODE-ERJAH401 -->
        <!-- <p>
        <span class="fw600">{{popupError}} </span> : For  <span class="fw600">
          <div *ngFor="let x of commaSeperated(popupError)"> {{x}} </div>
        </span> campus doesn't have the Approval Hierarchy set . Please contact HR team to add the same.
      </p> -->
      </div>
      <!-- <span class="select_option_span"> Please choose the below option: </span> -->


      <button type="button" class="p12-20 figma_background_blue_btn d-flex mx-auto mt-3"
        (click)="CloseTrfJobTitlepopUP()"> Okay </button>

    </div>
  </div>
  <!-- jobtitle popup -->
</div>
