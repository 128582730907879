
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TrfService {

  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');

  public loginUserId: any;
  public newUserId: any;
  public userData: any;

  constructor(private http: HttpClient) {
    let user = localStorage.getItem('userDetails');
    if (user != null) {
      // let userDetails = JSON.parse(user);
      // this.userData = userDetails;
      // let userId = userDetails.userId.toString();
      // this.newUserId = userId;
      // this.headers = this.headers.append('userId', this.newUserId);
      this.userData = jwtDecode(user)
      this.newUserId = this.userData.UserId
      this.headers = this.headers.append('userId', this.newUserId);
    }

  }

  createTrfForm(data) {
    return this.http.post(`${environment.apiUrl}/api/Jobs/AddTalentRequisition`, data, { headers: this.headers });
  }

  GetJobInfoByJahIdBrandId(jahId, brandId) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobInfoByJahIdBrandId?jahid=${jahId}&brandid=${brandId}`, { headers: this.headers });
  }

  GetTrfPagination(uid: any, status: any, pageNo: any, perSize: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTrfPagination?userId=${uid}&StatusId=${status}&PageNumber=${pageNo}&PagesizePerPage=${perSize}`, { headers: this.headers });
  }

  TalentRequisitionSearch(sId: any, id: any, pageNo: any, perSize: any, status: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/TalentRequisitionSearch?SearchItem=${sId}&userId=${id}&PageNumber=${pageNo}&PagesizePerPage=${perSize}&StatusId=${status}`, {
      headers: this.headers,
    });
  }

  getTrfForm(uid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisition?userId=${uid}`, { headers: this.headers });
  }

  GetTalentRequisitionById(tid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisitionById?id=${tid}`, { headers: this.headers });
  }

  GetTalentById(tid: any, jid: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTalentById?TalentId=${tid}&JobTitleId=${jid}`, { headers: this.headers });
  }

  GetTrfJobtitleRealted(jid: any, cid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTrfJobtitleRealted?JobTitle=${jid}&CountryId=${cid}`, { headers: this.headers });
  }

  GetJobTitlebyCampus(bid: any, cid: any, campusid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobTitlebyCampus?brandId=${bid}&countryId=${cid}&campus=${campusid}`, { headers: this.headers });
  }

  GetJahTitlebyCountry(tid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJahTitlebyCountry?countryId=${tid}`, { headers: this.headers });
  }

  ValidateJahTrfJobTitleOnCampus(brand: any, jobTitle: any, campus: any, JahId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/ValidateJahTrfJobTitleOnCampus?brand=${brand}&jobtitle=${jobTitle}&campus=${campus}&jahId=${JahId}`, { headers: this.headers });
  }
  ValidateTrfJobtitleOnCampus(jobTitle: any, campus: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/ValidateTrfJobtitleOnCampus?jobtitle=${jobTitle}&campus=${campus}`, { headers: this.headers });
  }

  UpdateTalentRequisition(id: any, status: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/UpdateTalentRequisition?id=${id}&status=${status}`, { headers: this.headers });
  }
  GetPopUpTrfHiringStage(tid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetPopUpTrfHiringStage?id=${tid}`, { headers: this.headers });
  }


  GetBrandDetailsByUserId(userId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetBrandDetailsByUserId?id=${userId}`, { headers: this.headers });
  }

  GetCountryCampusByBrandId(userId: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountryCampusByBrandId?id=${userId}`, { headers: this.headers });
  }

  GetTrfApprover(userId: any, pageSize: any, pageNo: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrfApprover?UserId=${userId}&PagesizePerPage=${pageSize}&PageNumber=${pageNo}`, { headers: this.headers });
  }

  GetUsersByCampus(userId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetUsersByCampus?UserId=${userId}`, { headers: this.headers });
  }

  addTrfApprover(data) {
    return this.http.post(`${environment.apiUrl}/api/Candidate/AddTrfApprover`, data, { headers: this.headers });
  }

  DeleteTrfApprover(id: any, status: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/DeleteTrfApprover?id=${id}&isActive=${status}`, { headers: this.headers });
  }

  GetTrfApproversById(id: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrfApproversById?Id=${id}`, { headers: this.headers });
  }

  GetApproverListByJobTitleIdCountryId(jobTitle: any, campus: any, deptId: any, budId: any, TypeOfAppointment:any, Category:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetApproverListByJobTitleIdCampusId?JobTitle=${jobTitle}&CampusId=${campus}&DepartmentId=${deptId}&BudOrNonBud=${budId}&Category=${Category}&TypeOfAppointment=${TypeOfAppointment}`, { headers: this.headers });
  }
  AddTrManager(data) {
    return this.http.post(`${environment.apiUrl}/api/Candidate/AddTrManager`, data, { headers: this.headers });
  }
  GetTrManager(userId: any, status: any, pageSize: any, pageNo: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrManager?userId=${userId}&StatusId=${status}&PagesizePerPage=${pageSize}&PageNumber=${pageNo}`, { headers: this.headers });
  }
  GetTrManagerById(trId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrManagerById?Id=${trId}`, { headers: this.headers });
  }

  TrManagerSearch(search: any, uId: any, status: any, pageSize: any, pageNo: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/TrManagerSearch?SearchItem=${search}&userId=${uId}&StatusId=${status}&PagesizePerPage=${pageSize}&PageNumber=${pageNo}`, { headers: this.headers });
  }

  GetTrManagerDetailsById(trId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrManagerDetailsById?Id=${trId}`, { headers: this.headers });
  }
  updateTrManagerApprovalStatus(trId: any, trApprovalStatus: any, approverStage: any, status: any, userId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/UpdateTrManagerApprovalStatus?TrManagerId=${trId}&approvalStatus=${trApprovalStatus}&approverStage=${approverStage}&status=${status}&approveduser=${userId}`, { headers: this.headers });
  }
  UpdateTrManagerReject(trId: any, status: any, resaon: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/UpdateTrManagerReject?TrManagerId=${trId}&status=${status}&Reason=${resaon}`, { headers: this.headers });
  }
  GetTrManagerStatusCount(id: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrManagerStatusCount?userId=${id}`, { headers: this.headers });
  }
  ValidateTrManagerJobTitleCampus(jobTitle: any, campusId: any, brandId: any, countryId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateTrManagerJobTitleCampus?JobTitle=${jobTitle}&CampusId=${campusId}&BrandId=${brandId}&CountryId=${countryId}`, { headers: this.headers });
  }

  GetMasterJobsDetailsById(id: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetMasterJobsDetailsById?Id=${id}`, { headers: this.headers });
  }


  Getinterviewtemplateearch(serarchval: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewQuestionTemplateSearch?searchItem=${serarchval}`, { headers: this.headers });
  }

  GetNationalitysearch(serarchval: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNationalitySearach?searchItem=${serarchval}`, { headers: this.headers });
  }

  // UAE
  Getgetworkpermitsearch(serarchval: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitTypeSearch?searchItem=${serarchval}`, { headers: this.headers });
  }
  //

  GetgetEntitysearch(serarchval: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEntitySearch?searchItem=${serarchval}`, { headers: this.headers });
  }

  GetJobNamesBySearch(serarchval: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNamesBySearch?searchItem=${serarchval}`, { headers: this.headers });
  }

  //
  trfApproverSearch(sId: any, uId: any, pageSize: any, pageNo: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/TrfApproverSearch?SearchItem=${sId}&UserId=${uId}&PagesizePerPage=${pageSize}&PageNumber=${pageNo}`, { headers: this.headers });
  }

  ValidateTrApproverCampusJobTitleBud(brandId: any, campusId: any, jobTitle: any, budgetId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateTrApproverCampusJobTitleBud?brandId=${brandId}&campusId=${campusId}&jobtileId=${jobTitle}&BudOrNonBudId=${budgetId}`, { headers: this.headers });
  }

  gettrApproverList(id: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetTrManagerApproversList?trmgrid=${id}`, { headers: this.headers });
  }

  ValidateTrApproverCampusFetchDepartment(brandId: any, campusId: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateTrApproverCampusFetchDepartment?brandId=${brandId}&campusId=${campusId}`, { headers: this.headers });
  }

  FetchTrManagerDepartment(campusid: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerDepartment?campusid=${campusid}`, { headers: this.headers });
  }

  FetchTrManagerBudOrNonBud(campusIds: any, departmentIds: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerBudOrNonBud?campusIds=${campusIds}&departmentIds=${departmentIds}`, { headers: this.headers });
  }

  FetchTrManagerCategory(campusIds: any, departmentIds: any, budOrNonBudValues: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerCategory?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}`, { headers: this.headers });
  }

  FetchTrManagerAppointment(campusIds: any, departmentIds: any, budOrNonBudValues: any, categories: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerAppointment?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}&categories=${categories}`, { headers: this.headers });
  }

  FetchTrManagerSubLevel(campusIds: any, departmentIds: any, budOrNonBudValues: any, categories: any, typeOfAppointments:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerSubLevel?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}&categories=${categories}&typeOfAppointments=${typeOfAppointments}`, { headers: this.headers });
  }

  FetchTrManagerJobtitle(departmentid: any, categoryid:any, sublevelid: any, countryid:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerJobtitle?departmentid=${departmentid}&categoryid=${categoryid}&sublevelid=${sublevelid}&countryid=${countryid}`, { headers: this.headers });
  }

  CheckJobtitleHasJah(countryid: any, jobtitleid:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/CheckJobtitleHasJah?countryid=${countryid}&jobtitleid=${jobtitleid}`, { headers: this.headers });
  }

  FetchTrManagerDepartment1(campusid: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerDepartment1?campusid=${campusid}`, { headers: this.headers });
  }

  FetchTrManagerBudOrNonBud1(campusid: any, departmentIds:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerBudOrNonBud1?campusIds=${campusid}&departmentIds=${departmentIds}`, { headers: this.headers });
  }

  FetchTrManagerCategory1(campusIds: any, departmentIds:any, budOrNonBudValues:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerCategory1?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}`, { headers: this.headers });
  }

  FetchTrManagerAppointment1(campusIds: any, departmentIds:any, budOrNonBudValues:any, categories:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerAppointment1?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}&categories=${categories}`, { headers: this.headers });
  }

  FetchTrManagerSubLevel1(campusIds: any, departmentIds:any, budOrNonBudValues:any, categories:any, typeOfAppointments:any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/FetchTrManagerSubLevel1?campusIds=${campusIds}&departmentIds=${departmentIds}&budOrNonBudValues=${budOrNonBudValues}&categories=${categories}&typeOfAppointments=${typeOfAppointments}`, { headers: this.headers });
  }




}
