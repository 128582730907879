<!-- <app-header></app-header> -->
<main class="new-job-listing-main pr-2" *ngIf="!loading">
  <!-- <div class="job-listing-head d-flex align-items-center mb-3">
    <img src="./assets/Images/new-back-arrow.svg" alt="back arrow" />
    <h1 class="fw600 fs26 ms-4"> IJP Job Listing </h1>
  </div> -->

  <section class="refer_tabs">
    <div class="ijp-top-partt white-background p16 br8 mb16 d-flex justify-content-between">
      <ul class="nav nav-tabs parent-nav-item" id="job-tabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="job-postings-tab" data-oggle="tab" data-target="#job-postings"
            type="button" role="tab" aria-controls="job-postings" aria-selected="true">
            Internal Job Postings - HR
          </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link" id="referrals-tab" data-oggle="tab" data-target="#referrals" type="button" role="tab" aria-controls="referrals" aria-selected="false">Referrals</button>
      </li> -->
      </ul>

      <div class="d-flex align-items-center justify-content-between gap12 mb-md-2">
        <!-- <div class="search-inputt relative">
          <input type="text" class="w-100" placeholder="Search" [(ngModel)]="searchItem" (keyup)="
            onSearchChange(UserId, pageNO, NoOfObjectsPerPage, searchItem)
          " />
          <img class="img-fluid absolute" src="./assets/Images/search.svg" alt="search icon" />
          <svg (click)="clearSearch()" *ngIf="searchItem" width="20" height="20" fill="currentColor"
            class="cross-iconn pointer bi bi-x" viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div> -->
      </div>
    </div>

    <div class="tab-content" id="job-tabs-content">
      <!-- Internal Job Postings Tab -->
      <!-- modal-buttons -->

      <!-- modal-buttons -->

      <div class="tab-pane fade show active" id="job-postings" role="tabpanel" aria-labelledby="job-postings-tab">
        <section class="job-listings-tabs-main mt-3">
          <ul class="nav nav-tabs white-background p16 br8 mb16" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'Active' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Active' }" class="nav-link" id="Active-tab" type="button"
                role="tab" (click)="selectTab('active')">
                Active <span class="candidiate-notification-numberr fw600">{{ijpAllCount?.activeCount}}</span>
              </button>
            </li>

            <!-- <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'Closed' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Closed' }" class="nav-link" id="Closed-tab" type="button"
                role="tab" (click)="selectTab('closed')">
                Closed
              </button>
            </li> -->

            <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'Approved' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Approved' }" class="nav-link" id="Approved-tab" type="button"
                role="tab" (click)="selectTab('approved')">
                Approved <span class="candidiate-notification-numberr fw600">{{ijpAllCount?.approvedCount}}</span>
              </button>
            </li>
            <!-- <li class="nav-item" role="presentation">
              <button
                [routerLink]="['.']"
                [queryParams]="{ tab: 'Drafts' }"
                [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Drafts' }"
                class="nav-link"
                id="Drafts-tab"
                type="button"
                role="tab"
                (click)="selectTab('drafts')"
              >
                Drafts
              </button>
            </li> -->
            <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'Pending' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Pending' }" class="nav-link" id="Pending-tab" type="button"
                role="tab" (click)="selectTab('pending')">
                Pending <span class="candidiate-notification-numberr fw600">{{ijpAllCount?.pendingCount}}</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'Rejected' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'Rejected' }" class="nav-link" id="Rejected-tab" type="button"
                role="tab" (click)="selectTab('rejected')">
                Rejected <span class="candidiate-notification-numberr fw600">{{ijpAllCount?.rejectedCount}}</span>
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button [routerLink]="['.']" [queryParams]="{ tab: 'All' }" [queryParamsHandling]="'merge'"
                [ngClass]="{ trfActive: activeTab === 'All' }" class="nav-link" id="All-tab" type="button" role="tab"
                (click)="selectTab('all')">
                All <span class="candidiate-notification-numberr fw600">{{ijpAllCount?.allCount}}</span>
              </button>
            </li>
          </ul>

          <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="All-tab-pane" role="tabpanel" aria-labelledby="All-tab"
              tabindex="0">
              <div class="card job-listing-main-card">
                <!-- <div
                  class="job-list-search-card d-xl-flex align-items-center justify-content-between p16"
                > -->
                <!-- <img src="./assets/Images/filter-black.svg" alt="filter" />
              <span class="fw400 fs16 mx-2">Filter</span> -->

                <!-- <app-pop-up><div class="filter-badge">3</div></app-pop-up> -->
                <!-- <app-pop-up
                [header]="'Second Modal Header'"
                [content]="'Content for the second modal'"
                [imageSrc]="'../../../assets/Images/filter-black.svg'"
                [imageSrc1]="'../../../assets/Images/approval.svg'"
                [buttonText]="'Filter'"
                [buttonClass]="'filters-button'"
                >
              </app-pop-up> -->

                <!-- <div
                    class="d-flex align-items-center gap12 job-list-add-buttons"
                  > -->
                <!-- <button type="button" class="secondary-btn">
                      + Add Campaign
                    </button> -->
                <!-- <button
                      type="button"
                      class="blue-btn"
                      routerLink="/create-job-post"
                    >
                      + Create Job
                    </button> -->
                <!-- </div> -->
                <!-- </div> -->

                <!-- job listings table starts -->
                <!-- job listings table data starts -->
                <!-- <div class="job-listings-table-main mt-3 table-responsive">

                <table class="table">
                  <thead class="d-flex">
                    <tr class="col-md-12 d-flex">
                      <th class="d-flex">
                        <p>Job ID</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Job Title</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Campus</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Category</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Type</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Created</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex">
                        <p>Cut-Off Date</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th class="d-flex" *ngIf="noTabData">
                        <p>Application</p>
                        <img
                        src="./assets/Images/sort-icons.svg"
                        alt="sort icons"
                      />
                      </th>
                      <th></th>

                    </tr>
                  </thead>

                  <tbody class="col-md-12">
                    <ng-container *ngFor="let x of jobLists ; let i = index">
                      <tr class="align-items-center tr-hover col-md-12">
                      <div class="table-row-content">
                          <div class=" p---10 align-items-center">
                            <td>
                              {{x.jobTitleId}}
                            </td>
                            <td>
                              {{x.jobTitleName}}
                            </td>
                            <td>
                              {{x.campusName}}
                            </td>
                            <td>
                              {{x.categoryName}}
                            </td>
                            <td>
                              {{x.typeOfEmployementName}}
                            </td>

                            <td class="">
                              {{x.ijpJobCreatedDate}}

                            </td>
                            <td>
                              {{x.jobTitleId}}
                            </td>
                            <td *ngIf="noTabData">
                              {{x.jobTitleId}}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="three-dots-button"
                                (click)="toggleKebabMenu(x)"
                              >
                                <img
                                  src="./assets/Images/three-dots.svg"
                                  alt="three dots"
                                />
                              </button>
                              <div
                                class="kebeb-menu"
                                *ngIf="x.showKebabMenu"
                                (mouseleave)="hideKebabMenu(x)"
                              >
                                <ul>
                                  <li >
                                    <app-pop-up
                                    [header]="'Test HR- (GIIS2024-190)'"
                                    [buttonText]="'Copy Ijp Link'"
                                    [imageSrc1]="'../../../assets/Images/QR-code.svg'"
                                    [link]="'www.Refferaljobslink.com'"
                                    [copyBtn]="'Copy Link'"
                                    [subHead]="'IJP'"
                                    [customButton]="'Download QR Code'"
                                    >
                                  </app-pop-up>
                                  </li>
                                  <li (click)="hideKebabMenu(x)">Close</li>
                                  <li (click)="hideKebabMenu(x)">Edit</li>
                                  <li (click)="hideKebabMenu(x)">
                                    Publish/ Unpublish
                                  </li>
                                </ul>
                              </div>
                            </td>

                          </div>
                          <div class="job-score-part d-flex justify-content-between align-items-center p12">
                            <div class="d-flex">
                              <div class="appJob_scoree appJob_scoree-new">
                                <p class="fs12">
                                  Job Score  <span> {{ x?.jobScore }}</span>
                                </p>
                                <p class="fs12">
                                  Job Score  <span> {{ x?.jobScore }}</span>
                                </p>
                                <p class="fs12">
                                  Job Score  <span> {{ x?.jobScore }}</span>
                                </p>

                              </div>

                            </div>
                            <div class="check_recruitment_Members d-flex">
                              <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                Recruitment members
                              </a>
                              <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                Job Approvers
                              </a>
                            </div>
                          </div>

                        </div>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div> -->

                <!-- new table -->
                <!-- <div class="loaderrrr mb-3" *ngIf="jobListingLoader">
                  <div class="loader--text"></div>
                </div> -->
                <div class="loader" *ngIf="jobListingLoader"></div>

                <div class="job-listings-table-main-1 mt-0" *ngIf="!ijpNoRecordsFound">
                  <table class="table mb-0">
                    <thead class="d-flex">
                      <tr class="col-md-12 d-flex p-0">
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('brand_IJPJobId')">
                            <p>Job ID</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('jobTitleName')">
                            <p>Job Title</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('campusName')">
                            <p>Campus</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('categoryName')">
                            <p>Category</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('typeOfEmployementName')">
                            <p>Type</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('ijpJobCreatedDate')">
                            <p>Created</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex pointer" (click)="sortTable('cutOffDate')">
                            <p>Cut-Off Date</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <ng-container *ngIf="!noTabData">
                          <th *ngIf="selectedTab!='Pending' && selectedTab!='Rejected'&& selectedTab!='Approved'">
                            <div class="d-flex pointer" (click)="sortTable('jobStatus')">
                              <p>Job Status</p>
                              <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                            </div>
                          </th>
                        </ng-container>
                        <!-- <th *ngIf="
                        activeTab != 'All' &&
                          IjpJobStatus != '99' &&
                          IjpJobStatus != '98' &&
                          IjpJobStatus != '97' &&
                          (IjpJobStatus == '96' ||  IjpJobStatus == '0')">
                          <button type="button" class="three-dots-button"></button>
                        </th> -->
                      </tr>
                    </thead>

                    <tbody class="col-md-12">
                      <ng-container *ngFor="let x of jobLists; let i = index">
                        <tr class="align-items-center tr-hover">
                          <div class="table-row-content">
                            <div class="col-md-12 d-flex new-myyy-hover p-0 align-items-center">
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                {{ x.brand_IJPJobId }}
                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                <div class="text-overflow-listing" pTooltip="{{ dataType(x.jobTitleName + '<br/>' + x.brand_IJPJobId) }}" tooltipPosition="bottom" tooltipStyleClass="custom-tooltip"  style="width: fit-content;">
                                  <p class="text-overflow-listing">
                                    {{ x.jobTitleName }}
                                  </p>
                                </div>

                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                <div class="ll-hh-20" *ngFor="
                                    let xy of commaSeperated(x.campusName)
                                  ">
                                  {{ xy }}
                                </div>
                                <!-- {{ x.campusName }} -->
                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                {{ x.categoryName }}
                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                {{ x.typeOfEmployementName }}
                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                " class="">
                                {{ x.ijpJobCreatedDate | date: 'dd-MMM-yyyy' }}
                              </td>
                              <td (click)="
                                  redirectedToDetailsPage(
                                    x.ijpBasicInformationId
                                  )
                                ">
                                {{ x.cutOffDate | date: 'dd-MMM-yyyy' }}
                              </td>
                              <ng-container *ngIf="!noTabData">
                                <td *ngIf="selectedTab!='Pending' && selectedTab!='Rejected'&& selectedTab!='Approved'">
                                  <div class="d-flex align-items-center justify-content-between">
                                    <div class="" *ngIf="selectedTab=='Active' && selectedTab!='Pending' && selectedTab!='Rejected'&& selectedTab!='Approved'">
                                     Active
                                    </div>
                                    <div class="" *ngIf="selectedTab=='All' && selectedTab!='Pending' && selectedTab!='Rejected'&& selectedTab!='Approved'">
                                      {{ x.jobStatus }}
                                    </div>
                                    <!-- <div class="d-flex ms-auto" *ngIf="
                                        activeTab != 'All' &&
                                        IjpJobStatus != '99' &&
                                        IjpJobStatus != '98' &&
                                        IjpJobStatus != '97' &&
                                        (IjpJobStatus == '96' ||
                                          IjpJobStatus == '0')
                                      "> -->
                                    <!-- <button (click)="toggleKebabMenu(x, $event)" type="button"
                                        class="three-dots-button">
                                        <img src="./assets/Images/three-dots.svg" alt="three dots" />
                                      </button> -->
                                    <!-- <div class="kebeb-menu" *ngIf="x.showKebabMenu" (mouseleave)="hideKebabMenu(x)">
                                        <ul> -->
                                    <!-- (click)="openCopyLinkModal()"   Copy IJP Link-->
                                    <!-- <li *ngIf="x.ijpJobStatus == '96'" data-toggle="modal"
                                            data-target="#staticBackdropCopyLink" (click)="
                                              openCopyModal(
                                                x,
                                                x.jobTitleName,
                                                x.brand_IJPJobId
                                              )
                                            ">
                                            Copy IJP Link -->
                                    <!-- <app-pop-up
                                        [header]="'Test HR- (GIIS2024-190)'"
                                        [buttonText]="'Copy IJP Link'"
                                        [imageSrc1]="'./assets/Images/QR-code.svg'"
                                        [link]="'www.Refferaljobslink.com'"
                                        [copyBtn]="'Copy Link'"
                                        [subHead]="'IJP'"
                                        [customButton]="'Download QR Code'"
                                        >
                                      </app-pop-up> -->
                                    <!-- </li> -->

                                    <!-- <p-dialog header="CopyLink" [(visible)]="copyLinkVisiblee" [modal]="true"  [draggable]="false" [resizable]="false">
                                      <ng-container *ngIf="subHead">
                                          <h6 class="subHead">{{subHead}}</h6>
                                      </ng-container>
                                      <div class="mx-auto">
                                          <div class="d-flex justify-content-between align-items-center mb-4">
                                              <ng-container *ngIf="link">
                                                  <a href="#" class="fs12 me-4">{{link}}</a>
                                              </ng-container>
                                              <ng-container *ngIf="copyBtn">
                                                  <button type="button" (click)="copyLinkToClipboard()" class="secondary-btn p-12-60">{{copyBtn}}</button>
                                              </ng-container>
                                          </div>
                                          <div class="d-flex justify-content-center p16">
                                              <ng-container *ngIf="imageSrc1">
                                                  <img [src]="imageSrc1" alt="Image">
                                              </ng-container>
                                          </div>
                                          <div class="d-flex justify-content-center">
                                              <ng-container *ngIf="customButton">
                                                  <button class="blue-btn p-12-60" (click)="downloadImage()" do>{{customButton}}</button>
                                              </ng-container>
                                          </div>
                                      </div>
                                      <ng-container *ngIf="content">
                                          <p>{{ content }}</p>
                                      </ng-container>
                                  </p-dialog> -->

                                    <!-- Button trigger modal -->

                                    <!-- <li data-toggle="modal" data-target="#jobCloseModal"
                                            *ngIf="x.ijpJobStatus == '96'" (click)="
                                              hideKebabMenu(x);
                                              closeJobTitle(
                                                x.jobTitleName,
                                                x.ijpBasicInformationId
                                              )
                                            ">
                                            Close
                                          </li> -->
                                    <!-- <li
                                          *ngIf="x.ijpJobStatus == '96'"
                                          [routerLink]="
                                            '/referal-job/' +
                                            x.ijpBasicInformationId
                                          "
                                        >
                                          Apply on Behalf
                                        </li> -->

                                    <!-- <li *ngIf="
                                              (x.ijpJobStatus == '0' ||
                                                x.ijpJobStatus == '96') &&
                                              IJPAll == false
                                            " (click)="
                                              EditIJP(x.ijpBasicInformationId)
                                            ">
                                            Edit
                                          </li> -->

                                    <!-- <li *ngIf="
                                              x.ijpJobStatus == '96' &&
                                              IJPAll == false
                                            " (click)="
                                              addapplicant(
                                                x.ijpBasicInformationId
                                              )
                                            ">
                                            Apply Now
                                          </li> -->
                                    <!-- <li *ngIf="x.ijpJobStatus == '96'"
                                          (click)="hideKebabMenu(x);getDate(x.ijpBasicInformationId)"
                                        >
                                          Publish/ Unpublish
                                        </li> -->
                                    <!-- </ul>
                                      </div> -->
                                  </div>
                                  <!-- </div> -->
                                </td>
                              </ng-container>
                              <!-- <td *ngIf="activeTab != 'All' &&
                                      IjpJobStatus != '99' &&
                                      IjpJobStatus != '98' &&
                                      IjpJobStatus != '97' &&
                                      (IjpJobStatus == '96' ||  IjpJobStatus == '0')"> -->
                              <!-- activeTab != 'All' &&
                                x.ijpJobStatus != '99' &&
                                x.ijpJobStatus != '98' &&
                                x.ijpJobStatus != '97' &&
                                (x.ijpJobStatus == '96' ||  x.ijpJobStatus == '0' ) -->

                              <!-- </td> -->
                            </div>

                            <div class="job-score-part d-flex justify-content-between align-items-center p-2">
                              <div class="d-flex col-md-6 px-0">
                                <div class="appJob_scoree appJob_scoree-new col-md-12 px-0">
                                  <!-- <p class="fs12 fw600 blue-color col-md-4">
                                    {{x.postJob}}
                                  </p> -->
                                  <p class="fs12 col-md-3 color-grey">
                                    Country &nbsp;
                                    <span class="fw600 blue-color fs14">
                                      {{x.countryName}}
                                    </span>
                                  </p>
                                  <p class="fs12 col-md-7 color-grey">
                                    School &nbsp;
                                    <span class="fw600 blue-color fs14">
                                      {{x.brandName}}
                                    </span>
                                  </p>
                                  <p class="fs12 col-md-11 color-grey ml-1">
                                    Minimum GSG experience(In years) - &nbsp;
                                    <span class="fw600 blue-color fs14"> {{x.gstOrganizationExperience}} </span>
                                  </p>
                                </div>
                              </div>

                              <div style="width: 100px !important" *ngIf="
                                  x.ijpJobPostedData != null &&
                                  x.ijpJobPostedData.length > 0 &&
                                  (IjpJobStatus == '95' ||
                                    IjpJobStatus == '96' ||
                                    IjpJobStatus == '0' ||
                                    IjpJobStatus == '98')
                                " class="table-dates-partt d-flex gap-3 align-items-center">
                                <!-- Button trigger modal -->
                                <button class="btn-transparent mr-3 p-0" type="button" data-toggle="modal"
                                  data-target="#staticBackdropDates">
                                  <img class="pointer" src="./assets/Images/calender-icon.svg "
                                    (click)="getDate(x.ijpBasicInformationId)" alt="calender" />
                                </button>

                                <button type="button" class="publishedd-btn" *ngIf="x.publishedStatus == 'Published'">
                                  Published
                                </button>
                                <button type="button" class="publish--expired-btn"
                                  *ngIf="x.publishedStatus == 'Publish Expired'">
                                  Published Expired
                                </button>
                              </div>

                              <!-- <ng-container *ngIf="acceptRejectModal">
                            <p class="blue-color pointer" role="button" (click)="showDialog(x.ijpBasicInformationId, x.ijpJobApprovedStatus, '97')"> show </p>
                            </ng-container> -->

                              <div class="check_recruitment_Members d-flex">
                                <!-- *ngIf="!x.jobSelectType == 'Archive' && !x.jobSelectType == 'Archive' " -->
                                <!-- <p>Check</p> -->
                                <button type="button" class="ml-2 recruit-members fs14 pointer fw600" (click)="
                                    GetIJPJobRecruitmentMembersPopUp(
                                      x.ijpBasicInformationId
                                    )
                                  " type="button" data-toggle="modal" data-target="#newRecruitModal">
                                  Recruitment Members
                                </button>

                                <!-- Modal -->
                                <div class="modal fade mt-5 recruit-popup" id="newRecruitModal"
                                  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                  aria-labelledby="recruitModalLabel" aria-hidden="true">
                                  <div class="modal-dialog">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h1 class="modal-title fs-5 fw600" id="recruitModalLabel">
                                          Recruitment Members
                                        </h1>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                                            viewBox="0 0 16 16">
                                            <path
                                              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                          </svg>
                                        </button>
                                      </div>
                                      <div class="modal-body position-relative">
                                        <ul class="experiences" *ngIf="requarray?.length">
                                          <!-- Experience -->
                                          <li class="green" *ngFor="
                                              let x of requarray;
                                              let i = index
                                            ">
                                            <div class="timeLine__data ml-5">
                                              <h1 class="fw600 fs14" >
                                                {{ x.hiringStage }}
                                              </h1>
                                              <div class="d-flex align-items-center" *ngFor="
                                                  let y of x.jobHiringMembers
                                                ">
                                                <!-- <p class="fs12 fw500 mt-2 d-flex align-items-center">
                                                  <strong>
                                                    {{ y.hiringUserName }}
                                                  </strong>
                                                  &nbsp; -
                                                  {{ y.HiringUserName }} &nbsp;
                                                </p> -->

                                                <p class="fs12 fw500 mt-2" [innerHTML]="y.hiringName">
                                                  {{ y.hiringName }}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                        <p *ngIf="!requarray?.length"
                                          class="no-recruitment-members width-max blue-color fw600">
                                          No Recruitment Members Added Yet
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button class="ml-2 recruit-members fs14 pointer fw600" (click)="
                                    GetIJPJobApproversPopUpData(
                                      x.ijpBasicInformationId
                                    )
                                  " type="button" data-toggle="modal" data-target="#newJobApprovers">
                                  Job Approvers
                                </button>

                                <!-- approvers list popup -->
                                <div class="modal fade mt-5 recruit-popup" id="newJobApprovers"
                                  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                  aria-labelledby="jobApproversLabel" aria-hidden="true">
                                  <div class="modal-dialog">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h1 class="modal-title fs-5 fw600" id="jobApproversLabel">
                                          Approvers List
                                        </h1>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                                            viewBox="0 0 16 16">
                                            <path
                                              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                          </svg>
                                        </button>
                                      </div>
                                      <div class="modal-body p-0">
                                        <!-- <div class="d-flex align-items-center">
                                          <h4></h4>
                                        </div> -->
                                        <div class="table-responsive" *ngIf="arraymy.length">
                                          <table class="my-new-table col-md-12 mt-2">
                                            <thead>
                                              <tr>
                                                <th class="pr30 t-w approver-member" style="
                                                    padding: 10px;
                                                    background: #764fdb;
                                                    width:165px;
                                                    min-width: 165px;
                                                  ">
                                                  Approval Level
                                                </th>
                                                <th class="t-w approver-member" style="
                                                    padding: 10px;
                                                    background: #764fdb;
                                                  ">
                                                  Approver Member
                                                </th>
                                                <th class="t-w approver-member" style="
                                                    padding: 10px;
                                                    background: #764fdb;
                                                  ">
                                                  Designation
                                                </th>
                                                <th class="t-w approver-member" style="
                                                    padding: 10px;
                                                    background: #764fdb;
                                                  ">
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <!-- {{arraymy|json}} -->
                                            <tbody>
                                              <tr *ngFor="
                                                  let x of arraymy;
                                                  let i = index
                                                ">
                                                <td class="approver-member" style="padding: 12px">
                                                  {{ x.approverLevel }}
                                                </td>
                                                <!-- <td style="padding: 10px">
                                                <ng-container *ngFor="let xy of commaSeperated(x.jobTitle)"> {{xy}} </ng-container>
                                              </td> -->
                                                <td class="" style="padding: 10px">
                                                  <p class="fs12 fw500 d-flex align-items-center">
                                                    <strong>
                                                      {{ x.approverName }}
                                                    </strong>
                                                    &nbsp; -
                                                    {{ x.ApproverName }} &nbsp;
                                                  </p>
                                                  <div class="p_fontt" *ngFor="
                                                      let xy of commaSeperated(
                                                        x.approverMemberName
                                                      )
                                                    ">
                                                    {{ xy }}
                                                  </div>
                                                </td>
                                                <td class="approver-member" style="padding: 12px">
                                                  <div class="p_fontt width-max" *ngFor="
                                                      let xy of commaSeperated(
                                                        x.designation
                                                      )
                                                    ">
                                                    {{ xy }}
                                                  </div>
                                                </td>
                                                <td>
                                                  <!-- {{x.approvedStatus}}  -->
                                                  <ng-container *ngIf="x.approvedStatus != 'Waiting For Approver1'"> {{x.approvedStatus}} </ng-container>
                                                  <ng-container *ngIf="x.approvedStatus == 'Waiting For Approver1'"> Pending For Approval </ng-container>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <p class="blue-color no-recruitment-members width-max fw600"
                                          *ngIf="!arraymy.length">
                                          No Job Approvers List
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- approvers list popup -->
                              </div>
                              <!-- <div class="check_recruitment_Members d-flex"> -->
                              <!-- *ngIf="!x.jobSelectType == 'Archive' && !x.jobSelectType == 'Archive' " -->
                              <!-- <p>Check</p> -->
                              <!-- <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                Recruitment members
                              </a>
                              <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                Job Approvers
                              </a>
                            </div> -->
                            </div>
                          </div>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="light-blue-bg px-3" *ngIf="ijpNoRecordsFound">
                  <p class="light-blue-bg p-3 text-center">No Records Found</p>
                </div>

                <!-- new table -->

                <!-- <h1 *ngIf="IJPAll">  all data </h1>
              <h1 *ngIf="IJPActive"> active data </h1>
              <h1 *ngIf="IJPApproved"> approved data </h1>
              <h1 *ngIf="IJPPending"> pending data </h1>
              <h1 *ngIf="IJPRejected"> rejected data </h1> -->
                <!-- job listings table data ends -->
                <!-- job listings table ends -->
              </div>
            </div>
            <!-- <div
          class="tab-pane fade"
          id="Acctive-tab-pane"
          role="tabpanel"
          aria-labelledby="Acctive-tab"
          tabindex="0"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="Approved-tab-pane"
          role="tabpanel"
          aria-labelledby="Approved-tab"
          tabindex="0"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="Pending-tab-pane"
          role="tabpanel"
          aria-labelledby="Pending-tab"
          tabindex="0"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="Rejected-tab-pane"
          role="tabpanel"
          aria-labelledby="Rejected-tab"
          tabindex="0"
        >
          ...
        </div> -->
          </div>
          <div class="card flex pagination p-0 br8 mt-3" *ngIf="!ijpNoRecordsFound">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount"
              [rowsPerPageOptions]="[10, 20, 30]" [showJumpToPageInput]="true"></p-paginator>
          </div>
        </section>
      </div>

      <!-- Referrals Tab -->
      <div class="tab-pane fade" id="referrals" role="tabpanel" aria-labelledby="referrals-tab">
        <section class="job-listings-tabs-main mt-3">
          <!-- Referrals Tabs -->
          <ul class="nav nav-tabs white-background p16 br8 mb16" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('All')" [ngClass]="{ trfActive: IJPAll }" class="nav-link active"
                id="All-tab" type="button" role="tab">
                All
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('Active')" [ngClass]="{ trfActive: IJPActive }" class="nav-link"
                id="Acctive-tab" type="button" role="tab">
                Active
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('Approved')" [ngClass]="{ trfActive: IJPApproved }" class="nav-link"
                id="Approved-tab" type="button" role="tab">
                Approved
              </button>
              <!-- </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="Closed-tab"
                data-oggle="tab"
                data-target="#Closed-tab-pane"
                type="button"
                role="tab"
                aria-controls="Closed-tab-pane"
                aria-selected="false"
              >
                Closed
              </button>
            </li> -->
            </li>

            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('Drafts')" [ngClass]="{ trfActive: IJPDrafts }" class="nav-link"
                id="Drafts-tab" type="button" role="tab">
                Drafts
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('Pending')" [ngClass]="{ trfActive: IJPPending }" class="nav-link"
                id="Pending-tab" type="button" role="tab">
                Pending
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="getListDetails('Rejected')" [ngClass]="{ trfActive: IJPRejected }" class="nav-link"
                id="Rejected-tab" type="button" role="tab">
                Rejected
              </button>
            </li>
          </ul>

          <!-- Referrals Tab Content -->
          <div class="tab-content mt-3" id="referrals-tabs-content">
            <div class="tab-pane fade show active" id="referrals-all" role="tabpanel"
              aria-labelledby="referrals-all-tab">
              <div class="card job-listing-main-card white-background">
                <div class="job-list-search-card d-xl-flex align-items-center justify-content-between p16"
                  *ngIf="jobLists.length > 0">
                  <div class="d-flex align-items-center justify-content-between gap12 mb-md-2">
                    <div class="search-inputt relative">
                      <input type="text" class="w-100" placeholder="Search" />
                      <img class="img-fluid absolute" src="./assets/Images/search.svg" alt="search icon" />
                    </div>
                    <!-- <img src="./assets/Images/filter-black.svg" alt="filter" />
                    <span class="fw400 fs16 mx-2">Filter</span>
                    -->

                    <!-- <app-pop-up><div class="filter-badge">3</div></app-pop-up> -->
                    <app-pop-up [header]="'Second Modal Header'" [content]="'Content for the second modal'"
                      [imageSrc]="'../../../assets/Images/filter-black.svg'"
                      [imageSrc1]="'../../../assets/Images/approval.svg'" [buttonText]="'Filter'"
                      [buttonClass]="'filters-button'">
                    </app-pop-up>
                  </div>

                  <div class="align-items-center gap12 job-list-add-buttons">
                    <button type="button" class="secondary-btn">
                      + Add Campaign
                    </button>
                    <button type="button" class="blue-btn" routerLink="/create-job-layout">
                      + Create Job
                    </button>
                  </div>
                </div>

                <!-- job listings table starts -->
                <!-- job listings table data starts -->
                <!-- <div class="job-listings-table-main mt-3 table-responsive">

                      <table class="table">
                        <thead class="d-flex">
                          <tr class="col-md-12 d-flex">
                            <th class="d-flex">
                              <p>Job ID</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Job Title</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Campus</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Category</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Type</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Created</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex">
                              <p>Cut-Off Date</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th class="d-flex" *ngIf="noTabData">
                              <p>Application</p>
                              <img
                              src="./assets/Images/sort-icons.svg"
                              alt="sort icons"
                            />
                            </th>
                            <th></th>

                          </tr>
                        </thead>

                        <tbody class="col-md-12">
                          <ng-container *ngFor="let x of jobLists ; let i = index">
                            <tr class="align-items-center tr-hover col-md-12">
                            <div class="table-row-content">
                                <div class=" p---10 align-items-center">
                                  <td>
                                    {{x.jobTitleId}}
                                  </td>
                                  <td>
                                    {{x.jobTitleName}}
                                  </td>
                                  <td>
                                    {{x.campusName}}
                                  </td>
                                  <td>
                                    {{x.categoryName}}
                                  </td>
                                  <td>
                                    {{x.typeOfEmployementName}}
                                  </td>

                                  <td class="">
                                    {{x.ijpJobCreatedDate}}

                                  </td>
                                  <td>
                                    {{x.jobTitleId}}
                                  </td>
                                  <td *ngIf="noTabData">
                                    {{x.jobTitleId}}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="three-dots-button"
                                      (click)="toggleKebabMenu(x)"
                                    >
                                      <img
                                        src="./assets/Images/three-dots.svg"
                                        alt="three dots"
                                      />
                                    </button>
                                    <div
                                      class="kebeb-menu"
                                      *ngIf="x.showKebabMenu"
                                      (mouseleave)="hideKebabMenu(x)"
                                    >
                                      <ul>
                                        <li >
                                          <app-pop-up
                                          [header]="'Test HR- (GIIS2024-190)'"
                                          [buttonText]="'Copy Ijp Link'"
                                          [imageSrc1]="'../../../assets/Images/QR-code.svg'"
                                          [link]="'www.Refferaljobslink.com'"
                                          [copyBtn]="'Copy Link'"
                                          [subHead]="'IJP'"
                                          [customButton]="'Download QR Code'"
                                          >
                                        </app-pop-up>
                                        </li>
                                        <li (click)="hideKebabMenu(x)">Close</li>
                                        <li (click)="hideKebabMenu(x)">Edit</li>
                                        <li (click)="hideKebabMenu(x)">
                                          Publish/ Unpublish
                                        </li>
                                      </ul>
                                    </div>
                                  </td>

                                </div>
                                <div class="job-score-part d-flex justify-content-between align-items-center p12">
                                  <div class="d-flex">
                                    <div class="appJob_scoree appJob_scoree-new">
                                      <p class="fs12">
                                        Job Score  <span> {{ x?.jobScore }}</span>
                                      </p>
                                      <p class="fs12">
                                        Job Score  <span> {{ x?.jobScore }}</span>
                                      </p>
                                      <p class="fs12">
                                        Job Score  <span> {{ x?.jobScore }}</span>
                                      </p>

                                    </div>

                                  </div>
                                  <div class="check_recruitment_Members d-flex">
                                    <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                      Recruitment members
                                    </a>
                                    <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                      Job Approvers
                                    </a>
                                  </div>
                                </div>

                              </div>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div> -->

                <!-- new table -->

                <div class="job-listings-table-main-1 mt-0" *ngIf="jobLists.length > 0; else noData">
                  <table class="table mb-0">
                    <thead class="d-flex">
                      <tr class="col-md-12 d-flex p-0">
                        <th>
                          <div class="d-flex">
                            <p>Job ID</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Job Title</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Campus</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Category</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Type</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Created</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <th>
                          <div class="d-flex">
                            <p>Cut-Off Date</p>
                            <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                          </div>
                        </th>
                        <ng-container *ngIf="!noTabData">
                          <th>
                            <div class="d-flex">
                              <p>Status</p>
                              <img src="./assets/Images/sort-icons.svg" alt="sort icons" />
                            </div>
                          </th>
                        </ng-container>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody class="col-md-12">
                      <ng-container *ngFor="let x of jobLists; let i = index">
                        <tr class="align-items-center tr-hover">
                          <div class="table-row-content">
                            <div class="col-md-12 d-flex">
                              <td>
                                {{ x.jobTitleId }}
                              </td>
                              <td>
                                {{ x.jobTitleName }}
                              </td>
                              <td>
                                {{ x.campusName }}
                              </td>
                              <td>
                                {{ x.categoryName }}
                              </td>
                              <td>
                                {{ x.typeOfEmployementName }}
                              </td>
                              <td class="">
                                {{ x.ijpJobCreatedDate | date }}
                              </td>
                              <td>
                                {{ x.cutOffDate | date }}
                              </td>
                              <ng-container *ngIf="!noTabData">
                                <td>
                                  {{ x.appliedStatus }}
                                </td>
                              </ng-container>
                              <td>
                                <div class="d-flex" *ngIf="x.appliedStatus !='notapplied'">
                                  <button type="button" class="three-dots-button p-0"
                                    (click)="toggleKebabMenu(x, $event)">
                                    <img src="./assets/Images/three-dots.svg" alt="three dots" />
                                  </button>
                                  <div class="kebeb-menu" *ngIf="x.showKebabMenu" (mouseleave)="hideKebabMenu(x)">
                                    <ul>
                                      <li>
                                        <app-pop-up [header]="'Test HR- (GIIS2024-190)'" [buttonText]="'Copy Ijp Link'"
                                          [imageSrc1]="
                                            '../../../assets/Images/QR-code.svg'
                                          " [link]="'www.Refferaljobslink.com'" [copyBtn]="'Copy Link'"
                                          [subHead]="'IJP'" [customButton]="'Download QR Code'">
                                        </app-pop-up>
                                      </li>
                                      <li (click)="hideKebabMenu(x)" data-toggle="modal" data-target="#jobCloseModal">
                                        Close
                                      </li>
                                      <li (click)="hideKebabMenu(x)">Edit</li>
                                      <li (click)="hideKebabMenu(x)">
                                        Publish/ Unpublish
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </div>

                            <div class="job-score-part d-flex justify-content-between align-items-center p12">
                              <div class="d-flex">
                                <div class="appJob_scoree appJob_scoree-new">
                                  <p class="fs12">
                                    Job Score <span> {{ x?.jobScore }}</span>
                                  </p>
                                  <p class="fs12">
                                    Job Score <span> {{ x?.jobScore }}</span>
                                  </p>
                                  <p class="fs12">
                                    Job Score <span> {{ x?.jobScore }}</span>
                                  </p>
                                </div>
                              </div>
                              <!-- <ng-container *ngIf="acceptRejectModal">
                                  <p class="blue-color pointer" role="button" (click)="showDialog('','','')"> show </p>
                                  </ng-container> -->
                              <div class="check_recruitment_Members d-flex">
                                <!-- *ngIf="!x.jobSelectType == 'Archive' && !x.jobSelectType == 'Archive' " -->
                                <!-- <p>Check</p> -->
                                <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                  Recruitment Members
                                </a>
                                <a href="javascript:void()" class="ml-2 recruit-members fs14">
                                  Job Approvers
                                </a>
                              </div>
                            </div>
                          </div>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <ng-template class="light-blue-bg px-3" #noData>
                  <p class="light-blue-bg p-3 text-center">
                    No records available
                  </p>
                </ng-template>
                <!-- new table -->

                <!-- <h1 *ngIf="IJPAll">  all data </h1>
                    <h1 *ngIf="IJPActive"> active data </h1>
                    <h1 *ngIf="IJPApproved"> approved data </h1>
                    <h1 *ngIf="IJPPending"> pending data </h1>
                    <h1 *ngIf="IJPRejected"> rejected data </h1> -->
                <!-- job listings table data ends -->
                <!-- job listings table ends -->
              </div>
            </div>
          </div>

          <div class="card flex pagination p-0 br8 mt-3" *ngIf="jobLists.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount"
              [rowsPerPageOptions]="[10, 20, 30]" [showJumpToPageInput]="true"></p-paginator>
          </div>
        </section>
      </div>
    </div>
  </section>
  <div class="figma-modal new-my-modal-header">
    <p-dialog header="Edit Profile" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }"
      [draggable]="false">
      <!-- <span class="p-text-secondary block mb-5">Update your information.</span> -->

      <div class="flex justify-content-end gap-2">
        <button class="secondary-btn" severity="secondary" (click)="visible = false; GetIJPReject(id, Reason, '99')">
          Reject
        </button>

        <button class="ml-3 blue-btn" (click)="visible = false; GetIJPApproval(id, approvalstatus, '97')">
          Approve
        </button>
      </div>
    </p-dialog>
  </div>
</main>

<!-- <div class="custom-loader" *ngIf="loading">
  <img src="./assets/Images/gsf-new-logo 2.svg" alt="Loader" />
</div> -->

<!-- <main class="job-listing-main">
  <div class="back-btn d-flex align-items-center">
    <button type="button">
      <img src="./assets/Images/back-arrow.svg" alt="back" />
    </button>
    <h1>Listing Job</h1>
  </div>
  <section class="job-listings-tabs-main mt-3">
    <div
      class="d-flex align-items-center justify-content-between nav-tabs-divv"
    >
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="All-tab"
            data-oggle="tab"
            data-target="#All-tab-pane"
            type="button"
            role="tab"
            aria-controls="All-tab-pane"
            aria-selected="true"
          >
            All
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Acctive-tab"
            data-oggle="tab"
            data-target="#Acctive-tab-pane"
            type="button"
            role="tab"
            aria-controls="Acctive-tab-pane"
            aria-selected="false"
          >
            Active
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Approved-tab"
            data-oggle="tab"
            data-target="#Approved-tab-pane"
            type="button"
            role="tab"
            aria-controls="Approved-tab-pane"
            aria-selected="false"
          >
            Approved
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Closed-tab"
            data-oggle="tab"
            data-target="#Closed-tab-pane"
            type="button"
            role="tab"
            aria-controls="Closed-tab-pane"
            aria-selected="false"
          >
            Closed
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Drafts-tab"
            data-oggle="tab"
            data-target="#Drafts-tab-pane"
            type="button"
            role="tab"
            aria-controls="Drafts-tab-pane"
            aria-selected="false"
          >
            Drafts
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Pending-tab"
            data-oggle="tab"
            data-target="#Pending-tab-pane"
            type="button"
            role="tab"
            aria-controls="Pending-tab-pane"
            aria-selected="false"
          >
            Pending
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Rejected-tab"
            data-oggle="tab"
            data-target="#Rejected-tab-pane"
            type="button"
            role="tab"
            aria-controls="Rejected-tab-pane"
            aria-selected="false"
          >
            Rejected
          </button>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between">
        <button type="button" class="blue-btn">+ Create Job</button>
        <div class="small-button ml-3">
          <button type="button">
            <img
              src="./assets/Images/filter-icon.svg"
              alt="filter icon"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="All-tab-pane"
        role="tabpanel"
        aria-labelledby="All-tab"
        tabindex="0"
      >
        <div class="job-listings-table-main mt-3">
          <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>
                  ID
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Title
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Campus
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Category
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Type
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Created On
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Deadline
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th>
                  Application
                  <img
                    src="./assets/Images/sort-icons.svg"
                    alt="sort icons"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <div style="border-bottom: 16px solid transparent"></div>
              <tr>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <input type="checkbox" />
                    <img
                      src="./assets/Images/star-icon.svg"
                      alt="star icon"
                    />
                    <img
                      src="./assets/Images/blue-star-icon.svg"
                      alt="star icon"
                    />
                  </div>
                </td>
                <td>{{ product.code }}</td>
                <td>{{ product.name }}</td>
                <td>{{ product.category }}</td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.quantity }}</td>
                <td>
                  <button
                    type="button"
                    class="three-dots-button"
                    (click)="toggleKebabMenu(product)"
                  >
                    <img
                      src="./assets/Images/three-dots.svg"
                      alt="three dots"
                    />
                  </button>
                  <div
                    class="kebeb-menu"
                    *ngIf="product.showKebabMenu"
                    (mouseleave)="hideKebabMenu(product)"
                  >
                    <ul>
                      <li (click)="hideKebabMenu(product)">Copy Ijp Link</li>
                      <li (click)="hideKebabMenu(product)">Close</li>
                      <li (click)="hideKebabMenu(product)">Edit</li>
                      <li (click)="hideKebabMenu(product)">
                        Publish/ Unpublish
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="Acctive-tab-pane"
        role="tabpanel"
        aria-labelledby="Acctive-tab"
        tabindex="0"
      >
        ...
      </div>
      <div
        class="tab-pane fade"
        id="Approved-tab-pane"
        role="tabpanel"
        aria-labelledby="Approved-tab"
        tabindex="0"
      >
        ...
      </div>
      <div
        class="tab-pane fade"
        id="Closed-tab-pane"
        role="tabpanel"
        aria-labelledby="Closed-tab"
        tabindex="0"
      >
        ...
      </div>
      <div
        class="tab-pane fade"
        id="Drafts-tab-pane"
        role="tabpanel"
        aria-labelledby="Drafts-tab"
        tabindex="0"
      >
        ...
      </div>
      <div
        class="tab-pane fade"
        id="Pending-tab-pane"
        role="tabpanel"
        aria-labelledby="Pending-tab"
        tabindex="0"
      >
        ...
      </div>
      <div
        class="tab-pane fade"
        id="Rejected-tab-pane"
        role="tabpanel"
        aria-labelledby="Rejected-tab"
        tabindex="0"
      >
        ...
      </div>
    </div>
  </section>
</main> -->
<div class="table-dates-partt--tt">
  <div class="modal fade" id="staticBackdropDates" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropDatesLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 *ngIf="postDates.length > 0" class="modal-title fs-5 fw500" id="staticBackdropDatesLabel">
            Manage Publish/Unpublish ({{ postDates[0]?.jobTitleName }}({{
            postDates[0]?.brand_IJPJobId
            }}))
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="editEndDate('-1', 'cancel', publishid)">
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <table class="w-100">
            <thead>
              <th>
                <div class="pl-2">Post Job To</div>
              </th>
              <th>
                <div class="px-3">Publish Start Date</div>
              </th>
              <th>Publish End Date</th>
              <th></th>
              <th></th>
            </thead>
            <div style="margin-bottom: 10px !important"></div>
            <tbody>
              <ng-container *ngFor="let m of postDates; let i = index">
                <tr class="my-1">
                  <td class="figma-ng-select">
                    <ng-select [disabled]="true" name="selectedPostDatesJobb" placeholder="Select post job to"
                      bindlabel="postjobto" bindvalue="postjobto" [(ngModel)]="m.postjobto"></ng-select>
                  </td>
                  <td class="">
                    <div class="figma-calender px-3">
                      <p-calendar [disabled]="true" [readonlyInput]="true" class="position-relative"
                        name="myPublishStartDatee" placeholder="Select date" [(ngModel)]="m.publishstartdate"
                        dateFormat="dd/mm/yy" [ngModelOptions]="{ standalone: true }" #myPublishStartDatee="ngModel"
                        inputId="icon" [showIcon]="true"></p-calendar>
                    </div>
                  </td>
                  <td class="figma-calender">
                    <p-calendar [disabled]="i != value && disableEndDate" class="position-relative"
                      name="myPublishStartDatee" placeholder="Select date" [minDate]="publishstartdate"
                      [(ngModel)]="m.publishenddate" dateFormat="dd/mm/yy" [ngModelOptions]="{ standalone: true }"
                      #myPublishStartDatee="ngModel" inputId="icon" [showIcon]="true"></p-calendar>
                  </td>
                  <td>
                    <span class="blue-color fs12 fw500 mx-2 pointer" *ngIf="(enableEdit && !enableSave) || i != value"
                      (click)="editEndDate(i, 'edit', '')">Edit</span>
                    <span class="blue-color fs12 fw500 mx-2 pointer" *ngIf="enableSave && i == value"
                      (click)="updateEndDate(m.id, m.publishenddate)">Save</span>
                    <span class="blue-color fs12 fw500 pointer" *ngIf="!enableEdit && enableSave && i == value"
                      (click)="editEndDate('-1', 'cancel', publishid)">Cancel</span>
                  </td>
                  <td class="">
                    <div class="ms-3">
                      <button *ngIf="
                          (m.postJobStatus != null &&
                            m.postJobStatus != 'Unpublished') ||
                          m.postJobStatus == 'Published'
                        " (click)="
                          publishJob(
                            m.id,
                            m.publishstartdate,
                            m.publishenddate,
                            'Unpublished',
                            m.ijpBasicInformationId,
                            ''
                          )
                        " type="button" data-dismiss="modal" aria-label="Close" class="blue-btn px-3">
                        Unpublish
                      </button>
                      <button *ngIf="
                          m.postJobStatus == null ||
                          m.postJobStatus == 'Unpublished'
                        " (click)="
                          publishJob(
                            m.id,
                            m.publishstartdate,
                            m.publishenddate,
                            'Published',
                            m.ijpBasicInformationId,
                            ''
                          )
                        " type="button" data-dismiss="modal" aria-label="Close" class="blue-btn px-3">
                        Publish
                      </button>
                    </div>
                  </td>
                </tr>
                <div style="margin-bottom: 10px !important"></div>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal copy-link-modal-main fade" id="staticBackdropCopyLink" data-bs-backdrop="static"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropCopyLinkLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropCopyLinkLabel">
          {{ copyJobTitle }} -({{ copyJobID }})
        </h1>
        <button type="button" class="btn-close" class="close" data-dismiss="modal" aria-label="Close">
          <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
      </div>
      <div class="modal-body px-0" *ngFor="let jobs of ijpJobPostedData; let i = index">
        <div class="copy-link-modal-content">
          <div class="d-flex align-items-center justify-content-between figma-input gap-3">
            <p class="col-md-2 px-0 col-1">{{ jobs.postjobto }}</p>
            <input class="col-md-2" type="text" class="mt-0" [(ngModel)]="jobs.postJobLink" #myInput />

            <button type="button" class="blue-btn copy_link_stylz px-3 col-md-2 ml-3" (click)="copyText(myInput)">
              Copy Link
            </button>
            <!-- qr code -->
            <img style="width: 100px" *ngIf="qrCodes[i]" [src]="qrCodes[i]"
              alt="QR Code for {{ ijpJobPostedData[i].postJobLink }}" class="qr-code-img col-md-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal copy-link-modal-main fade" id="jobCloseModal" data-backdrop="static" data-keyboard="false"
  tabindex="-1" aria-labelledby="jobCloseModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 text-center" id="jobCloseModalLabel">
          Please provide the reason for closing the job
          <span class="fw600 fs14"> `{{ closeModalJobTitle }}` </span>
        </h1>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body">
        <div class="copy-link-modal-content my-textarea">
          <textarea class="w-100" placeholder="Enter your reason" rows="6" [(ngModel)]="closeJobtextArea"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="secondary-btn" data-dismiss="modal">
          Don't Close
        </button>
        <button type="button" class="blue-btn" data-dismiss="modal" [disabled]="!closeJobtextArea" (click)="
            publishJob(
              0,
              '',
              '',
              'Closed',
              publishJobpostjobtoid,
              closeJobtextArea
            )
          ">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- job clode modal -->
<!-- <div class="modal copy-link-modal-main  fade" id="jobCloseModal" data-backdrop="static" data-keyboard="false"
     tabindex="-1" aria-labelledby="jobCloseModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 text-center" id="jobCloseModalLabel">
              Please provide the reason for closing the job <br> <strong> `{{closeModalJobTitle}}` </strong> </h1>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" >
            <div class="copy-link-modal-content my-textarea">
              <textarea class="w-100" placeholder="Enter your reason" rows="6" [(ngModel)]="closeJobtextArea"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="secondary-btn" data-dismiss="modal">Don't Close</button>
            <button type="button" class="blue-btn" data-dismiss="modal" [disabled]="!closeJobtextArea"
            (click)="publishJob(0,'','','Closed',publishJobpostjobtoid,closeJobtextArea)"> Close </button>
          </div>
        </div>
      </div>
      </div> -->
<!-- job clode modal -->
<p-toast></p-toast>
