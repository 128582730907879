import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class jobService {
  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-origin', '*')
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');

  public successJob: boolean = false;
  public userData: any;
  public bulkJobobj: any;
  public hiringList: any;
  responceData: any;
  bulkData: any;

  constructor(private http: HttpClient) {
    let user = localStorage.getItem('userDetails');
    if (user != null) {
      // let userDetails = JSON.parse(user);
      // this.userData = userDetails;
      // let userId = userDetails.userId.toString();
      // this.headers = this.headers.append('userId', userId);

      this.userData = jwtDecode(user)
      let userId = this.userData.UserId
      this.headers = this.headers.append('userId', userId);
    }
  }

  public GetJobDetails(data) {
    console.log(this.headers);
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetDetailsByJobFilter`,
      data,
      { headers: this.headers }
    );
  }

  public GetjobFilterdetails(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetDataForAddFilter`,
      data,
      { headers: this.headers }
    );
  }

  public GetJobBookmark(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Bookmark/GetDetailsByFilter`,
      data,
      { headers: this.headers }
    );
  }

  public GetJdJobDetails(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetJDDetailsByJobFilter`,
      data,
      { headers: this.headers }
    );
  }

  public createBasicInfo(data) {
    return this.http.post(`${environment.apiUrl}/api/Jobs/CreateJob`, data, {
      headers: this.headers,
    });
  }

  public createDraftInfo(data) {
    return this.http.post(`${environment.apiUrl}/api/Jobs/CreateDraft`, data, {
      headers: this.headers,
    });
  }

  public editJobInfoGetById(id) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetJobDetailsById?jobID=${id}`,
      { headers: this.headers }
    );
  }

  public getDetailsBySearch(filterValue) {
    let params = new HttpParams();
    params = params.set('jobTitle', filterValue);
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetDetailsByJobTitle`,
      { params }
    );
  }

  deleteJobId(id, status, reason) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/UpdateJobStatus?JobId=${id}&Status=${status}&Reason=${reason}`,
      { headers: this.headers }
    );
  }

  getPaginationList(number) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetListofJobDetails?pageNumber=${number}`,
      { headers: this.headers }
    );
  }

  insertBookmark(params) {
    return this.http.post(
      `${environment.apiUrl}/api/Bookmark/InsertBookmark`,
      params,
      { headers: this.headers }
    );
  }

  setSuccessPop(data) {
    this.successJob = data;
  }

  getSuccessPop() {
    return this.successJob;
  }

  getUserForRole() {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {
      headers: this.headers,
    });
  }

  getRoleByuser(id) {
    return this.http.get(
      `${environment.apiUrl}/api/Role/GetRolesByUser?userId=${id}`,
      { headers: this.headers }
    );
  }

  GetCloneJob_Title() {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetCloneJob_Title`,
      { headers: this.headers }
    );
  }

  GetCampusbyCountry(brand, country, campus, jobTitle) {
    var data = { "brand": brand, "country": country, "campuses": campus, "jobTitle": jobTitle }
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetCampusbyCountry`,
      data,
      { headers: this.headers }
    );
  }

  GetJobForClone(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetJobForClone`,
      data,
      { headers: this.headers }
    );
  }

  InsertCloneJobTitle(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Master/InsertCloneJobTitle`,
      data,
      { headers: this.headers }
    );
  }


  getUserPermission() {
    return this.userData;
  }
  GetUserRolesHrInterviewDetailsByUserId(data) {
    return this.http.get(`${environment.apiUrl}/api/User/GetUserRolesHrInterviewDetailsByUserId?userId=${data}`, { headers: this.headers });
  }

  deleteDraft(id) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/DeleteDraft?jobId=${id}`,
      { headers: this.headers }
    );
  }

  getJobStatusApproval(id, statusApproval) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/UpdateJobApproval?jobId=${id}&approvalStatus=${statusApproval}`,
      { headers: this.headers }
    );
  }

  getJobApproval(id, statusApproval, approverStage, status, approveduser) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/UpdateJobApproval?jobId=${id}&approvalStatus=${statusApproval}&approverStage=${approverStage}&status=${status}&approveduser=${approveduser}`,
      { headers: this.headers }
    );
  }

  getMyJobDetails(userId) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetMyjobs?userId=${userId}`,
      { headers: this.headers }
    );
  }

  createApproverFilter(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/GetjobApprovalFilter`,
      data,
      { headers: this.headers }
    );
  }

  getJobTitleData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobTitle`, {
      headers: this.headers,
    });
  }

  getDraftDetails(data, userId) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetDraftDetails?jobStatus=${data}&userId=${userId}`,
      { headers: this.headers }
    );
  }

  GetInterviewTemplates(jobid, data) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetInterviewTemplates?jobTitle=${jobid}&HiringStage=${data}`,
      { headers: this.headers }
    );
  }

  getStagedata(isManagesettings = false) {
    return this.http.get(
      `${environment.apiUrl}/api/Master/GetInterviewStages?isManagesettings=${isManagesettings}`,
      { headers: this.headers }
    );
  }

  GetQuestionDetails(jobid, data) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetQuestionDetails?JobId=${jobid}&StageId=${data}`,
      { headers: this.headers }
    );
  }

  GetCountryCurrencySymbol(data) {
    return this.http.get(
      `${environment.apiUrl}/api/Master/GetCountryCurrencySymbol?id=${data}`,
      { headers: this.headers }
    );
  }

  Jobpublished(id, status) {
    // let params = new HttpParams();
    let params = new HttpParams().set('id', id).set('status', status);
    return this.http.get(`${environment.apiUrl}/api/Jobs/AddJobUnpublished`, { params: params }
    );
  }

  GetJobsByBrandId(data) {
    return this.http.get(
      `${environment.apiUrl}/api/Master/GetJobsByBrandId?brandId=${data}`,
      { headers: this.headers }
    );
  }

  GetJobTitlewithlevels() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetBrandId`, {
      headers: this.headers,
    });
  }

  GetMasterJobsByBrandId(data: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetMasterJobsByBrandId?brandId=${data}`, {
      headers: this.headers,
    });
  }

  GetApproverListByBrandCountry(brandId, countryId) {
    // let params = new HttpParams();
    let params = new HttpParams().set('brandId', brandId).set('countryId', countryId);
    return this.http.get(`${environment.apiUrl}/api/Master/GetApproverListByBrandCountry`, { params: params }
    );
  }

  GetUsersList() {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersList`, {
      headers: this.headers,
    });
  }

  GetJobApprovalHierarchy(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobApprovalHierarchy?id=${id}`, {
      headers: this.headers,
    });
  }

  GetJahPagination(id:any,pageNo:any,perSize:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJahPagination?id=${id}&PageNumber=${pageNo}&PagesizePerPage=${perSize}`, {
      headers: this.headers,
    });
  }
  JobApprovalHierarchySearch(sId:any,id:any,pageNo:any,perSize:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/JobApprovalHierarchySearch?SearchItem=${sId}&userId=${id}&PageNumber=${pageNo}&PagesizePerPage=${perSize}`, {
      headers: this.headers,
    });
  }

  DeleteJah(id: any, active: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/DeleteJah?id=${id}&isActive=${active}`, {
      headers: this.headers,
    });
  }

  cloneTrf(id: any, active: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetCloneDetails?trfId=${id}&jobtitleId=${active}`, {
      headers: this.headers,
    });
  }


  AddJobApprovalHierarchy(data) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/AddJobApprovalHierarchy`,
      data,
      { headers: this.headers }
    );
  }
  setBulkObject(data) {
    console.log(data)
    this.bulkJobobj = data;
  }

  getBulkObject() {
    return this.bulkJobobj;
  }

  setHiringStage(data) {
    this.hiringList = data;
  }

  getHiringStage() {
    return this.hiringList;
  }
  getJahHiringList(jahId, brandId) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStageByJobTitleBrand?jobTitleId=${jahId}&brandId=${brandId}`, { headers: this.headers })
  }

  getJahApproverdata(id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJahApproverList?JobTitle=${id}`, { headers: this.headers })
  }
  ValidateJahJobTitleOnCampusjobtitle(brand: any, jobTitle: any, campus: any, jahId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/ValidateJahJobTitleOnCampus?brand=${brand}&campus=${campus}&jobtitle=${jobTitle}&jahId=${jahId}`, {
      headers: this.headers,
    });
  }
  GetTalentRequisitionByTRFStatus(userId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisitionByTRFStatus?userId=${userId}`, {
      headers: this.headers,
    });
  }

  GetLoginDetails(username: any, passward: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetHiringDetailsByuser?username=${username}&passward=${passward}`, {
      headers: this.headers,
    });
  }
  cloneJobapi(id: any, jid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetCloneDetails?trfId=${id}&jobtitleId=${jid}`), { headers: this.headers }
  }

  GetHiringStageInterviewType(id) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStageInterviewType?id=${id}`, { headers: this.headers })
  }

  public AddCloneJobTitle(data: any) {
    return this.http.post(
      `${environment.apiUrl}/api/Jobs/AddCloneJobTitle`, data,
      { headers: this.headers }
    );
  }

  seteditResponce(data) {
    this.responceData = data
  }
  geteditResponce() {
    return this.responceData

  }
  GetHiringTeamDepartmentStageList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringTeamDepartmentStageList`, { headers: this.headers });
  }

  GetWorkPermitTypeByCountryId(countryid: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitTypeByCountryId?id=${countryid}`, { headers: this.headers });
  }

  GetHiringStageHiringMember(jobId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetHiringStageHiringMember?id=${jobId}`, { headers: this.headers });
  }

  setBulkData(data: any) {
    this.bulkData = data;
  }

  getBulkData() {
    return this.bulkData;
  }

  GetJobApprovalListByJobId(id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobApprovalListByJobId?JobId=${id}`, { headers: this.headers })
  }
  GetUserListByBrandCountryCampus(id){
    return this.http.get(`${environment.apiUrl}/api/User/GetUserListByBrandCountryCampus?brandId=${id}`, { headers: this.headers })
  }

  GetJobsFilterNames(id:any, Status:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?UserId=${id}&Status=${Status}`, {
      headers: this.headers,
    });
  }
  // GetJobsFilterNames(id:any) {
  //   return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames?UserId=${id}`, {
  //     headers: this.headers,
  //   });
  // }
  getJobFilter(data) {
    return this.http.post(`${environment.apiUrl}/api/Jobs/GetJobFilters1`, data, {
      headers: this.headers,
    });
  }

  jobSerach(searchitem, PagesizePerPage, PageNumber, JobStatus, UserId) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/JobsSearch1/Jobs Search1?searchitem=${searchitem}&PagesizePerPage=${PagesizePerPage}&PageNumber=${PageNumber}&JobStatus=${JobStatus}&UserId=${UserId}`,
        { headers: this.headers });
  }
  // jobSerach(pagesize, pageno, userName, status, user) {
  //   return this.http.get(`${environment.apiUrl}/api/Jobs/JobsSearch/Jobs Search?PagesizePerPage=${pagesize}&PageNumber=${pageno}&searchitem=${userName}&JobStatus=${status}&userId=${user}`,
  //       { headers: this.headers });
  // }

  GetJobPostDetails(id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobPostDetails?JobId=${id}`, { headers: this.headers })
  }
  UpdateJobPostDetails(id,postName,Published) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/UpdateJobPostDetails?JobId=${postName}&PostJobTo=${id}&status=${Published}`, { headers: this.headers })
  }

  GetOverallJobsCount(id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetOverallJobsCount?UserId=${id}`, { headers: this.headers })
  }

  public GetJobDetailNameById(id) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetJobDetailsNamesById1?id=${id}`,
      { headers: this.headers }
    );
  }
  public GetJobDetailsNamesByIdForStatus(id,userId) {
    return this.http.get(
      `${environment.apiUrl}/api/Jobs/GetJobDetailsNamesByIdForStatus?id=${id}&userId=${userId}`,
      { headers: this.headers }
    );
  }

  GetTalentRequisitionById(tid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisitionById?id=${tid}`, { headers: this.headers });
  }

  // New API's For Jobs Filter based on selected filter
  GetJobsFilterCountryNames(brandIds: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?brandIds=${brandIds}`, {
      headers: this.headers,
    });
  }

  GetJobsFilterCampusNames(countryIds:any) {
    // return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?UserId=${id}&Status=${Status}`, {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?countryIds=${countryIds}`, {
      headers: this.headers,
    });
  }

  GetJobsFilterJobTitleNames(campusIds:any) {
    // return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?UserId=${id}&Status=${Status}`, {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsFilterNames1?campusIds=${campusIds}`, {
      headers: this.headers,
    });
  }

  GetJobsFilterBrandAndCountryNames(brandIds: any, countryIds: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountrycampuslist?brand=${brandIds}&country=${countryIds}`, {
      headers: this.headers,
    });
  }
}
