import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-origin', '*')
  .set('Cache-Control', 'no-cache')
  .set('Pragma', 'no-cache');

public userData: any;
  constructor(private http: HttpClient) {
    let user = localStorage.getItem('userDetails');
    if (user != null) {
      this.userData = jwtDecode(user)
      console.log(this.userData);
      let userId = this.userData.UserId
      this.headers = this.headers.append('userId', userId);
    }
   }

   public getJobsCount() {
    const obj={
      brand: 0,
      userId:this.userData.UserId
    }
    return this.http.post(`${environment.apiUrl}/api/Report/GetJobsCount`, obj, {
      headers: this.headers,
    });
  }

  // public getSourceOfHireCount() {
  //   const obj={
  //     brand: 0,
  //     source:0,
  //     userId:this.userData.UserId
  //   }
  //   return this.http.post(`${environment.apiUrl}/api/Report/GetSourceOfHire`, obj, {
  //     headers: this.headers,
  //   });
  // }
  getSourceOfHireCount() {
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPCount?userid=${this.userData.UserId}`, { headers: this.headers });
  }
  getMyInterviewCount() {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetMyInterviewCount?userid=${this.userData.UserId}`, { headers: this.headers });
  }

  getMyInterviews(statusId,searchitem) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetMyInterviews?userid=${this.userData.UserId}&status=${statusId}&search=${searchitem}`, { headers: this.headers });
  }
  public getJobRequisitionsCount(){
    const obj={
      brand: 0,
      userId:this.userData.UserId
    }
    return this.http.post(`${environment.apiUrl}/api/Report/GetTalentrequisitionCount?userid`, obj, { headers: this.headers });
  }

  getMyActivites(myTaskActiveTab) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = `2025-02-26`;
    console.log(formattedDate);
    if(myTaskActiveTab == 'Today') {
      return this.http.get(`${environment.apiUrl}/api/Dashboard/GetDashboardMyTaskByUserId?userid=${this.userData.UserId}&date=${formattedDate}`, { headers: this.headers });
      // return this.http.get(`${environment.apiUrl}/api/Dashboard/GetDashboardMyTaskByUserId?userid=${4}&date=${formattedDate}`, { headers: this.headers });
    } else {
      // return this.http.get(`${environment.apiUrl}/api/Dashboard/GetDashboardMyTaskByUserId?userid=${this.userData.UserId}`, { headers: this.headers });
      return of({
        "count": 8,
        "tasks": [
            {
                "message": "Job - Waiting",
                "miniMessage": "JobName: Manual testing QA",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Job - Searching",
                "miniMessage": "JobName: Test Naukri",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Job - Hiring",
                "miniMessage": "JobName: FED Developer",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Job - Listing",
                "miniMessage": "JobName: Manual Tester",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Hiried",
                "miniMessage": "JobName: Finance department",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "On Process",
                "miniMessage": "JobName: HR",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Job - Waiting For Approval",
                "miniMessage": "JobName: New test HR",
                "date": "03-03-2025",
                "time": "04:08 PM"
            },
            {
                "message": "Job - Waiting For Approval",
                "miniMessage": "JobName: Shashipal job",
                "date": "03-03-2025",
                "time": "04:08 PM"
            }
        ]
    })
    }
  }

  getCampusRecruiters() {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetCampusRecruiters?userid=${this.userData.UserId}`, { headers: this.headers });
  }
}
