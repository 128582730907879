import { Component, Injector, OnInit, ViewEncapsulation, Renderer2, OnDestroy, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { activityService } from 'src/app/services/activityService';
import { candidateService } from 'src/app/services/candidateService';
import { jobService } from 'src/app/services/jobservice';
import { userService } from 'src/app/services/userservice';
import { AppComponentBase } from 'src/app/shared/app.component-base';
import { environment } from 'src/environments/environment';
import { roleService } from 'src/app/services/roleService'
import { CommonServiceService } from 'src/app/services/common-service.service';
// import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends AppComponentBase implements OnInit {
  public userName: any;
  public image: any;
  public path = environment.apiUrl;
  public enableBellicon: boolean = true;
  public userDetails: any;
  public newUserId: any;
  public userPermission: any;
  public responseCount: any;
  public candidateCount: any;
  public activityData: any;
  public approveData: any;
  public approveEnable: boolean = false;
  public manageData: any;
  public manageEnable: boolean = false;
  public messageClass: boolean = false;
  public bookmarkClass: boolean = false;
  public notificatioinClass: boolean = false;
  public acivityClass: boolean = false;
  newDatatoken: any;
  roleStatus: boolean = false;
  resetSeg: any;
  userreset: any;
  hasRole9: boolean = false;

  roleByUserName: any;
  allUserRoles: any;

  constructor(
    injector: Injector,
    public route: ActivatedRoute,
    public activityService: activityService,
    public userSerive: userService,
    public router: Router,
    public candidateService: candidateService,
    public jobSer: jobService,
    public roleService: roleService,
    private renderer: Renderer2,
    private el: ElementRef,
    public commonservice: CommonServiceService
  ) {
    super(injector);
    let user = localStorage.getItem('userDetails');
    this.newDatatoken = localStorage.getItem('userDetails')
    if (user != null) {
      // this.userDetails = JSON.parse(user);
      // let userId = this.userDetails.userId.toString();
      // this.newUserId = userId;
      this.userDetails = jwtDecode(user)
      let userId = this.userDetails.UserId
      this.newUserId = userId;
    }
    this.allUserRoles = sessionStorage.getItem('All User Roles');
  }
  myRolesVariable: any;
  tokenVal: any;
  allInterviewRoles: any;
  myRolesVariableName: any;
  userAllRolesPermissions: any = [];
  loginAllUserRoles: any = [];
  ngOnInit(): void {

    // Original array
    const achievements = [
      '"Awards": ""',
      '"Milestones": ""',
      '"Recognitions": ""',
      '"Grants_and_Funding": ""',
      '"Patents": ""',
      '"Publications": ""',
      '"Conference_Presentations": ""',
      '"Competitions": ""'
    ];


    if (this.allUserRoles) {
      this.userAllRolesPermissions = JSON.parse(this.allUserRoles);
    }
    // console.log(this.userAllRolesPermissions);


    // Function to clean a string
    function cleanString(input: string): string {
      return input.replace(/[^a-zA-Z0-9_]/g, ''); // Keeps only alphanumeric characters and underscores
    }

    // Clean the array
    const cleanedAchievements = achievements.map(cleanString);

    // Output the result
    console.log(cleanedAchievements, 'HIIIIIIIIIi');

    // alert('header')
    var url = this.router.url

    var seg = url.split("/").pop()
    var seg1 = url.split('?').slice(-2)
    var seg2 = url.split('=').slice(-3)
    this.resetSeg = seg1
    this.userreset = seg2
    this.tokenVal = seg

    if (this.tokenVal != 'jobs' && this.tokenVal != 'trf') {
      let params = {
        token: this.tokenVal
      }
      // this.errormMsg=false
      this.userSerive.createUserLogin(params).subscribe((res: any) => {

        if (res?.userInfo?.token) {
          this.userAllRolesPermissions = res.userInfo.userRole;          
          this.hasPermission(res.userInfo.userRole[0].permissionName)
          this.userSerive.setUserId(res.userInfo.token);
          this.loginAllUserRoles = res.userInfo.userRole;
          sessionStorage.setItem('All User Roles', JSON.stringify(this.loginAllUserRoles));
          setTimeout(() => {
            this.router.navigate(['/manage/jobs']);
          }, 100);
          setTimeout(() => {
            this.roleService.roleTypeUserId(res?.userInfo.userId).subscribe((res:any)=>{
              this.allInterviewRoles = res;
              const hasHRManager = !this.allInterviewRoles.some(role => role.roleTypeName.includes('Interviewer'));
              const hasInterviewer = this.allInterviewRoles.some(role => role.roleTypeName.includes('Interviewer') && this.allInterviewRoles.length==1);
  
              if (hasHRManager) {
                this.myRolesVariable = true;
                //this.myRolesVariableName = 'HR Manager';
              }
              if (hasInterviewer && !hasHRManager) {
                //this.myRolesVariableName = 'Interviewer';
                //this.myRolesVariableName = 'Interviewer';
                this.myRolesVariable = false;
                // alert(this.myRolesVariable)
                }
                if (res) {  
                  this.myRolesVariableName = JSON.stringify(this.allInterviewRoles)  
                    sessionStorage.setItem('User Roles', this.myRolesVariableName);
                  this.roleByUserName = sessionStorage.getItem('User Roles');
                  // alert(this.roleByUserName);
                }
            })
          }, 100);
        }
        
      })

    }

    setTimeout(() => {
      this.getUserDetails();
    }, 500);


    this.getPermission();
    this.roletypeId(this.newUserId)
    // this.dashboardCount();
    // this.notificationList();
    // this.jobapprovalCount();
    // this.onClick(8);
    // let active:any = document.getElementsByClassName("start-list__item")[1];
    //  active.classList.add('active');
    // this.enableCandidateJobBell();
    // this.tab = 'tab8';
    // this.tab = 'tab13';
    // this.jobsGrey = false;
    // this.jobsWhite = true;
    // this.enableDashboardBell(1)

    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      this.tab = storedTab;
    } else {
      this.tab = 'tab8';
    }
    this.documentClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      this.onDocumentClick(event);
    });

    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.hideDiv = true;
      }
    });
    // setTimeout(() => {
    //   this.roleByUserName = sessionStorage.getItem('User Roles');
    //   alert(this.roleByUserName);
    // }, 3000);
  }

  // hasPermission(permission: string): boolean {
  //   // return this.userAllRolesPermissions.includes(permission);
  //   return this.userAllRolesPermissions.some(p => p.permissionName === permission);
  // }

  hasPermission(permission: string | string[]): boolean {
    if (Array.isArray(permission)) {
      return permission.some(requiredPermission =>
        this.userAllRolesPermissions.some(p => p.permissionName === requiredPermission)
      );
    } else {
      return this.userAllRolesPermissions.some(p => p.permissionName === permission);
    }
  }


  hasMultiplePermissions(permissions: string[]): boolean {
    return permissions.every(requiredPermission =>
      this.userAllRolesPermissions.some(p => p.permissionName === requiredPermission)
    );
  }

  hideDiv = false;



  useWhiteImage: boolean = false;
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  tab3: any;
  tab4: any;
  tab5: any;
  tab6: any;
  tab7: any;
  tab8: any;
  tab9: any;
  tab10: any;
  tab11: any;
  tab12: any;
  tab13: any;
  tab14: any;
  tab20: any;
  tab21: any;
  tab99: any;

  enquiryWhite: boolean = false;
  enquiryGrey: boolean = true;
  onClick(check) {
    if (check == 'enquiry') {
      this.tab = 'tabEnquiry';
      this.router.navigateByUrl('/enquiry');
    }
    if (check == 8) {
      this.tab = 'tab8';
      this.enableBellicon = true;
      this.router.navigateByUrl('/manage/jobs');
      this.tab = 'tab8';
      this.jobsGrey = false;
      this.jobsWhite = true;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 19) {
      this.tab = 'tab19';
      this.enableBellicon = true;
      this.router.navigateByUrl('/jah');
      this.tab = 'tab19';
      this.jahGrey = false;
      this.jahWhite = true;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 21) {
      this.tab = 'tab21';
      this.enableBellicon = true;
      this.router.navigateByUrl('/trf');
      this.tab = 'tab21';
      this.trfGrey = false;
      this.trfWhite = true;
      this.jahGrey = true;
      this.jahWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 14) {
      this.tab = 'tab14';
      this.enableBellicon = true;
      this.router.navigateByUrl('/candidate/candidates');
      this.tab = 'tab14';
      this.applicantGrey = false;
      this.applicantWhite = true;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.dashboardWhiteIcon = false;
      this.dashboardGreyIcon = true;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 20) {
      this.tab = 'tab20';
      this.router.navigateByUrl('/interviews');
      this.tab = 'tab20';
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.interviewGrey = false;
      this.interviewWhite = true;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 4) {
      this.tab = 'tab4';
      this.enableBellicon = true;
      this.messageClass = false;
      this.notificatioinClass = false;
      this.acivityClass = false;
      this.bookmarkClass = true;
      this.router.navigateByUrl('/bookmarks');
      this.tab = 'tab4';
      this.bookmarksWhite = true;
      this.dashboardGreyIcon = true;
      this.bookmarksGrey = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    } else if (check == 11) {
      this.tab = 'tab11';
      this.enableBellicon = true;
      this.router.navigateByUrl('/settings/user');
      this.tab = 'tab11';
      this.userManGrey = false;
      this.userManWhite = true;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.dashboardWhiteIcon = false;
      this.dashboardGreyIcon = true;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.applicantWhite = false;
      this.applicantGrey = true;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 12) {
      this.tab = 'tab12';
      this.enableBellicon = true;
      this.router.navigateByUrl('/settings/roles');
      this.tab = 'tab12';
      this.roleManGrrey = false;
      this.roleManWhite = true;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.notificationGrey = true;
      this.userManGrey = true;
      this.userManWhite = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.notificationWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.applicantWhite = false;
      this.applicantGrey = true;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 13) {
      this.tab = 'tab13';
      this.enableBellicon = true;
      this.router.navigateByUrl('/system-fields');
      this.tab = 'tab13';
      this.dataManGrey = false;
      this.dataManWhite = true;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    } else if (check == 99) {
      this.tab = 'tab99';
      this.enableBellicon = true;
      this.router.navigateByUrl('/admin');
      this.tab = 'tab99';
      this.dataManGreyy = false;
      this.dataManWhitee = true;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
    }
    // else if (check == 11) {
    //   this.tab = 'tab11';
    // } else if (check == 12) {
    //   this.tab = 'tab12';
    // } else if (check == 13) {
    //   this.tab = 'tab13';
    // } else if (check == 15) {
    //   this.tab = 'tab15';
    // } else if (check == 19) {
    //   this.tab = 'tab19';
    // } else if (check == 20) {
    //   this.tab = 'tab20';
    // } else if (check == 21) {
    //   this.tab = 'tab21';
    // } else {
    //   this.tab = 'tab14';
    // }
    localStorage.setItem('activeTab', this.tab);
  }

  isCandidate: boolean = false;

  getUserDetails() {
    let userInfo = this.userSerive.getUserId();
    // if (userInfo) {
    if (userInfo != null) {
      this.userDetails = jwtDecode(userInfo)
      this.userName = this.userDetails.UserFullName;
      this.image = userInfo.userPhotoPath;
      if (userInfo.isCandidate) {
        this.isCandidate = true;
      } else {
        this.isCandidate = false;
      }
    } else if (this.resetSeg[0] != '/reset-password' && userInfo == null) {
      this.router.navigate(['/no-access']);
    } else {
      this.router.navigateByUrl('reset-password?id=' + this.userreset[1]);
    }
    // }
  }

  notificationWhite = false;
  notificationGrey = true;

  disableNotificationBell(check) {
    this.enableBellicon = false;
    this.notificatioinClass = true;
    this.bookmarkClass = false;
    this.messageClass = false;
    this.acivityClass = false;
    this.router.navigateByUrl('/notification');
    if (check == 5) {
      this.tab = 'tab5';
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.notificationWhite = true;
      this.notificationGrey = false;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.dashboardGreyIcon = true;
      this.reportsGrey = true;
      this.dashboardWhiteIcon = false;
      // this.reportsWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }

  getPermission() {
    // this.userPermission = this.userDetails.userRole;
    this.roleService.GetUserRolesHrInterviewDetailsByUserId(this.userId).subscribe((res: any) => {
      this.userPermission = res.userRole
      this.approveData = this.userPermission?.filter(
        (ele) => ele.permissionName == 'Approve'
      );
      if (this.approveData?.length > 0) {
        this.approveEnable = true;
      }
      this.manageData = this.userPermission?.filter(
        (ele) => ele.permissionName == 'Manage Setting'
      );
      if (this.manageData?.length > 0) {
        this.manageEnable = true;
      }
    })

  }

  disableActivityBell(check) {
    this.enableBellicon = true;
    this.notificatioinClass = false;
    this.bookmarkClass = false;
    this.messageClass = false;
    this.acivityClass = true;
    this.router.navigateByUrl('activitylog');
    this.interviewGrey = true;
    this.interviewWhite = false;
    this.dataManGreyy = true;
    this.dataManWhitee = false;
  }

  userManGrey = true;
  userManWhite = false;
  // enableUserBell(check) {
  //   if (check == 11) {

  //   }
  // }

  // enableCMS(){
  //   this.enableBellicon = true;
  //   this.router.navigateByUrl('http://18.142.45.251/giisportal/culture');
  // }

  dataManGrey = true;
  dataManGreyy = true;
  dataManWhite = false;
  dataManWhitee = false;
  // enableSystemFieldsBell(check) {
  //   if (check == 13) {
  //   window.scrollTo(0, 0);

  //   }
  // }

  // admin tab work
  // activeadmin(check) {
  //   if (check == 99) {
  //   window.scrollTo(0, 0);

  //   }
  // }
  // admin tab work

  cmsGrey = true;
  cmsWhite = false;
  cmssCheck(check) {
    this.enableBellicon = true;
    // this.router.navigateByUrl('/system-fields');
    if (check == 19) {
      this.tab = 'tab19';
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.cmsGrey = true;
      this.cmsWhite = true;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }

  roleManGrrey = true;
  roleManWhite = false;

  // enableRoleBell(check) {
  //   if (check == 12) {

  //   }
  // }

  jobsGrey = true;
  jobsWhite = false;
  jahGrey = true;
  jahWhite = false;
  // enableJah(check) {

  //   if (check == 19) {

  //   }
  // }
  trfGrey = true;
  trfWhite = false;
  // enableTRF(check) {

  //   if (check == 21) {

  //   }
  // }
  // enableJobBell(check) {

  //   if (check == 8) {

  //   }
  // }
  interviewGrey = true;
  interviewWhite = false;
  // enableInterviews(check) {
  //   // this.router.navigateByUrl('/interviews');
  //   if (check == 20) {

  //   }
  // }

  enableMessageBell(check) {
    this.enableBellicon = true;
    this.messageClass = true;
    this.bookmarkClass = false;
    this.notificatioinClass = false;
    this.acivityClass = false;
    this.router.navigateByUrl('/messages');
    if (check == 3) {
      this.tab = 'tab3';
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }
  bookmarksWhite = false;
  bookmarksGrey = true;

  // enableBookmarkBell(check) {
  //   if (check == 4) {

  //   }
  // }
  dashboardWhiteIcon = true;
  dashboardGreyIcon = false;

  enableDashboardBell(check) {
    this.enableBellicon = true;
    this.router.navigateByUrl('/dashboard');
    if (check == 1) {
      this.tab = 'tab1';
      this.dashboardWhiteIcon = true;
      this.dashboardGreyIcon = false;
      this.reportsGrey = true;
      this.reportsWhite = false;
      this.bookmarksWhite = false;
      this.bookmarksGrey = true;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.applicantGrey = true;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }
  jobApprovalGrey = true;
  jobApprovalWhite = false;

  jdjobApprovals(check) {
    this.enableBellicon = true;
    this.router.navigateByUrl('/jobApprovals');
    if (check == 7) {
      this.tab = 'tab7';
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.jobApprovalGrey = false;
      this.jobApprovalWhite = true;
      this.dashboardGreyIcon = true;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.dashboardWhiteIcon = false;
      this.dashboardGreyIcon = true;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }
  schedule(check) {
    this.router.navigateByUrl('/interviews');
    if (check == 9) {
      this.tab = 'tab9';
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }
  addapplicantWhite = false;
  addapplicantGrey = true;
  addApplicant(check) {
    if (check == 15) {
      this.tab = 'tab15';
    }
    this.router.navigateByUrl('/add-applicant');
    this.addapplicantWhite = true;
    this.addapplicantGrey = false;
    this.dashboardGreyIcon = true;
    this.dashboardWhiteIcon = false;
    this.applicantGrey = true;
    this.applicantWhite = false;
    this.bookmarksGrey = true;
    this.bookmarksWhite = false;
    this.interviewGrey = true;
    this.interviewWhite = false;
    this.jahGrey = true;
    this.jahWhite = false;
    this.trfGrey = true;
    this.trfWhite = false;
    this.dataManGreyy = true;
    this.dataManWhitee = false;
  }

  dashboardCount() {
    let pageNumber = 0;
    let pageSize = 5;
    let statusValue = 1;
    let userId = this.userId;
    this.candidateService
      .getCandidateList(pageNumber, pageSize, statusValue, userId)
      .subscribe((res: any) => {
        if (res.result && res.result[0] && res.result[0].totalCount) {
          this.candidateCount = res.result[0].totalCount;
        }
      });
    // this.activityService.getDashboardCount(campusName).subscribe((res: any) => {
    //   this.responseCount = res;
    //   if (this.responseCount && this.responseCount.newCandidates) {
    //     this.candidateCount = this.responseCount.newCandidates
    //   }
    // })
  }

  public approvalCount;
  jobapprovalCount() {
    let params = {
      jobTitle: '',
      category: '',
      campus: [''],
      teamMember: '',
      boardOfEducation: '',
      startDate: new Date(),
      endDate: new Date(),
      jobStatus: 1,
      pagesizePerPage: 20,
      pageNumber: 0,
      jobApprovalStatus: '',
      userId: this.userId,
    };

    this.jobSer.GetJdJobDetails(params).subscribe((res: any) => {
      if (res) this.approvalCount = res[0]?.totalCount;
    });
  }

  notificationList() {
    this.activityService.notificationDetails().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.activityData = res.length || [];
      } else {
        this.activityData = 0;
      }
    });
  }

  routeSettings() {
    this.router.navigateByUrl('/language');
  }
  logout() {
    let rout: any = window.location.href;
    let data = rout.split('/')[3];

    if (this.userDetails && this.userDetails.isCandidate) {
      this.router.navigateByUrl('/candidates/login');
    } else {
      this.router.navigateByUrl('/login');
    }

    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('accessToken')
    localStorage.removeItem('User Roles')
    localStorage.removeItem('All User Roles')
    localStorage.clear();
    sessionStorage.clear();
  }
  applicantGrey = true;
  applicantWhite = false;
  // enableCandidateBell(check) {

  //   if (check == 14) {

  //   }
  // }
  enableCandidateMessageBell() {
    this.enableBellicon = true;
    this.router.navigateByUrl('/candidate/messages');
  }
  enableCandidateBookmarkBell() {
    this.enableBellicon = true;
    this.router.navigateByUrl('/candidate/bookmarks');
  }
  disableCandidateNotificationBell() {
    this.enableBellicon = false;
    this.router.navigateByUrl('/candidate/notification');
  }
  enableCandidateJobByIdBell() {
    this.enableBellicon = true;
    this.router.navigateByUrl('/candidate/candidateProfile/' + this.newUserId);
  }
  enableCandidateJobBell() {
    this.enableBellicon = true;
    this.router.navigateByUrl('/candidate/jobs');
  }

  reportsGrey = true;
  reportsWhite = false;

  enableReport(check) {
    this.enableBellicon = true;
    this.router.navigateByUrl('/reports');
    if (check == 2) {
      this.tab = 'tab2';
      this.reportsGrey = false;
      this.reportsWhite = true;
      this.dashboardGreyIcon = true;
      this.dashboardWhiteIcon = false;
      this.bookmarksGrey = true;
      this.bookmarksWhite = false;
      this.notificationWhite = false;
      this.notificationGrey = true;
      this.jobApprovalGrey = true;
      this.jobApprovalWhite = false;
      this.jobsGrey = true;
      this.jobsWhite = false;
      this.applicantGrey = true;
      this.applicantWhite = false;
      this.userManGrey = true;
      this.userManWhite = false;
      this.roleManGrrey = true;
      this.roleManWhite = false;
      this.dataManGrey = true;
      this.dataManWhite = false;
      this.addapplicantGrey = true;
      this.addapplicantWhite = false;
      this.interviewGrey = true;
      this.interviewWhite = false;
      this.jahGrey = true;
      this.jahWhite = false;
      this.trfGrey = true;
      this.trfWhite = false;
      this.dataManGreyy = true;
      this.dataManWhitee = false;
    }
  }

  // onSwitch(check) {
  //   switch (check) {
  //     case 1:
  //       return 'tab1';
  //     case 2:
  //       return 'tab2';
  //     case 3:
  //       return 'tab3';
  //     case 4:
  //       return 'tab4';
  //     case 5:
  //       return 'tab5';
  //     case 6:
  //       return 'tab6';
  //     case 7:
  //       return 'tab8';
  //     case 8:
  //       return 'tab9';
  //     case 9:
  //       return 'tab10';
  //     case 10:
  //       return 'tab11';
  //   }
  // }
  menuVisible = false;

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  selectMenuItem() {
    this.menuVisible = false;
  }
  private documentClickListener: () => void;
  isMenuVisible = false;
  ngAfterViewInit() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
        this.onDocumentClick(event);
      });
    }
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  toggleSettingsMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  showSettingsMenu() {
    this.isMenuVisible = true;
  }

  hideSettingsMenu() {
    this.isMenuVisible = false;
    sessionStorage.removeItem('jobsClosedAppsSessionVariableeee');
  }
  removeScheduleStorageItem() {
    sessionStorage.removeItem('scheduleStorageItem');
  }

  onMenuItemClick(route: string) {
    this.hideSettingsMenu(); // Hide the menu immediately
    this.router.navigate([route]); // Navigate to the route
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.new-icons-part') ||
      (event.target as HTMLElement).closest('.new-icon-tooltip-part');
    if (!clickedInside) {
      this.hideSettingsMenu();
    }
  }
  roleStatus1: boolean = false
  hasRole2: boolean = false;
  hasRole6: boolean = false;
  roletypeId(uid: any) {
    this.roleService.roleTypeUserId(uid).subscribe((res: any) => {
      this.hasRole2 = false;
      this.hasRole6 = false;
      this.hasRole9 = false;
      for (let i = 0; i < res.length; i++) {
        var element = res[i].roleTypeId;
        if (element === "2" || element === "8" || element === "7" || element === "3") {
          this.hasRole2 = true;
        }
        if (element === "6" || element === "8" || element === "7" || element === "3") {
          this.hasRole6 = true;
        }
        if (element === "9") {
          this.hasRole9 = true;
        }
      }
    })
  }
}
