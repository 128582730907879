import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { EmployeeService } from 'src/app/services/employee.service';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { userService } from 'src/app/services/userservice';
import { TooltipModule } from 'primeng/tooltip';

interface HiringCard {
  title: string;
  key: string;
  buttonText: string;
  command: () => void;
  createButton?: boolean;
  hasMoreContent?: boolean;
  stats?: { value: number; label: string }[];
}
@Component({
  selector: 'app-employee-homepage',
  templateUrl: './employee-homepage.component.html',
  styleUrls: ['./employee-homepage.component.scss']
})
export class EmployeeHomepageComponent {
  tabsDetails: { title: string, content: string, route:string}[] = [];
  activeTabIndexDetailsViewUpdate: number = 0;
  activeTabIndexTaskDetails: number = 0;
  activeTabIndexInterviewDetails: number = 0;
  public path = environment.websiteurl;
  // Fix: Correctly define content type to be an array of objects instead of a string
  activitesTabs: {
    title: string;
    content: { title: string; description: string; date: string; time: string; }[];
    filteredContent: { title: string; description: string; date: string; time: string; }[];
  }[] = [
    { title: "Today", content: [], filteredContent: [] },
    // { title: "All Task", content: [], filteredContent: [] }
  ];
  // interviewsTabs: {
  //   title: string;
  //   content: {
  //     name: string;
  //     date: string;
  //     time: string;
  //     stage: string;
  //     mode: string;
  //     location: string;
  //     role: string;
  //     reference: string;
  //   }[];
  // }[] = [];

  // Method to extract only the content of a specific tab
  getTabContent(tabTitle: string) {
    return this.interviewsTabs.find(tab => tab.title === tabTitle)?.content || [];
  }
  today:any;
  activeRoute: string = '';
  constructor(private route: ActivatedRoute, private router: Router, private employeeService:EmployeeService,public userService: userService,) {

    this.today = new Date();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
      }
    });
    if (this.router.url === '/' || this.router.url === '/employee-dashboard') {
      this.activeRoute = '/employee-dashboard';
    }
  }
  newDatatoken:any;
  userDetails :any ={};
  goToHomePage() {
    this.router.navigate(['/dashboard-reports']);
  }
  onTabChangeTab(event: any) {
    this.activeTabIndexDetailsViewUpdate = event.index; // Update active tab index
    const selectedTab = this.tabsDetails[event.index];

    if (selectedTab.route) {
      this.router.navigate([selectedTab.route]);
    }
  }
  updateActiveTab() {
    const currentRoute = this.router.url;
    const activeTabIndex = this.tabsDetails.findIndex(tab => tab.route === currentRoute);

    if (activeTabIndex !== -1) {
      this.activeTabIndexDetailsViewUpdate = activeTabIndex;
    }
  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab();
      }
    });

    // Set the active tab when the component initializes
    this.updateActiveTab();
    this.tabsDetails = [
      { title: 'HR Dashboard', content: 'HR Dashboard Content', route: '/dashboard-reports' },
      { title: 'Home Page', content: 'HomePage Content',  route: '/employee-dashboard' },

    ];
    let user = localStorage.getItem('userDetails');
        this.newDatatoken = localStorage.getItem('userDetails')
        if (user != null) {
          this.userDetails = jwtDecode(user)
          console.log(this.userDetails.UserFullName
            , 'userdeatils------')
        }

    const allTasks = [
      {
        title: "Interview Scheduled",
        description: "An interview has been scheduled for the HR Associate (DES_128...)",
        date: "06-06-24",
        time: "10:30 AM"
      },
      {
        title: "Interview Scheduled",
        description: "An interview has been scheduled for the HR Associate (DES_128...)",
        date: "06-06-24",
        time: "10:30 AM"
      },
      {
        title: "Document Submission",
        description: "Submit required documents before the deadline.",
        date: "07-06-24",
        time: "12:00 PM"
      }
    ];

    // Filter tasks for "Today"
    const todayDate = "06-06-24";
    const todayTasks = allTasks.filter(task => task.date === todayDate);

    // this.activitesTabs = [
    //   { title: "Today", content: todayTasks },
    //   { title: "All Task", content: allTasks }
    // ];
    this.activitesTabs = [
      { title: "Today", content: [], filteredContent:[] },
      // { title: "All Task",  content: [], filteredContent:[] },
    ];
    this.getJobsCount();
    this.getSourceOfHireCount();
    this.getMyInterviewCount();
    this.getMyInterviews('Today',this.searchitem);
    this.getJobRequisitionsCount();
    this.getMyActivites();
    this.getCampusRecruiters();


    this.loginUserRole = sessionStorage.getItem('User Roles');
    if (this.loginUserRole) {
      this.loginUserRole = JSON.parse(this.loginUserRole);
    }


    this.roleExists = this.loginUserRole.some(role => role.roleTypeName === "Employee" && this.loginUserRole.length==1);
    console.log(this.roleExists, 'user Role----')
    if(this.roleExists == true){
      this.tabsDetails = this.tabsDetails.filter(tab => tab.title === 'Home Page');
      this.activeTabIndexDetailsViewUpdate = 0;
      this.showTabs = false;
      this.router.navigate(['/employee-dashboard']);
    }else{
      this.tabsDetails = [...this.tabsDetails];
      this.activeTabIndexDetailsViewUpdate = 0;
      this.showTabs = true;
    }

  }
  loginUserRole:any = [];
  roleExists:boolean=false;
  showTabs:boolean = true;
//   formattedDate: string;
//   searchitem:string='';
//   activeJobs:any;
//   totalCount:any;
//   todayCount:any;
//   upcomingCount:any;

//   updateHiringSummary(key: string, data: any) {
//     this.hiringSummary = this.hiringSummary.map(card => {
//       if (card.key === key) {
//         return { ...card, stats: data };
//       }
//       return card;
//     });
//   }

//   getJobsCount() {
//     this.employeeService.getJobsCount().subscribe((res: any) => {
//       console.log('Jobs Count:', res);
//       this.updateHiringSummary('activeJobs', [{ value: res.activeJobs, label: 'Active' }]);
//     });
//   }

//   getSourceOfHireCount() {
//     this.employeeService.getSourceOfHireCount().subscribe((res: any) => {
//       console.log('Source of Hire:', res);
//       this.updateHiringSummary('totalCount', [{ value: res.totalCount, label: 'Jobs (Positions)' }]);
//     });
//   }

//   getMyInterviewCount() {
//     this.employeeService.getMyInterviewCount().subscribe((res: any) => {
//       console.log('Interview Count:', res);
//       this.updateHiringSummary('interviewCounts', [
//         { value: res.data.todayCount, label: 'Today’s' },
//         { value: res.data.upcomingCount, label: 'Upcoming' },
//         { value: 0, label: 'Pending Feedbacks' }
//       ]);
//     });
//   }

// interviewsTabs: any[] = [
//   { title: "Today", content: { data: [] } },
//   { title: "Upcoming", content: { data: [] } },
//   { title: "Completed", content: { data: [] } }
// ];

// get activeTab() {
//   return this.interviewsTabs?.[this.activeTabIndexInterviewDetails];
// }

// getMyInterviews(status: string, searchitem:any): void {
//   this.employeeService.getMyInterviews(status,searchitem).subscribe((res: any) => {
//     // Check if response data exists
//     const hasData = res && Array.isArray(res.data) && res.data.length > 0;

//     this.interviewsTabs = [
//       { title: "Today", content: status === "Today" && hasData ? res : { data: [] } },
//       { title: "Upcoming", content: status === "Upcoming" && hasData ? res : { data: [] } },
//       { title: "Completed", content: status === "Completed" && hasData ? res : { data: [] } }
//     ];
//   });
// }
// selectedTab:any = 'Today';
// searchItemsByTab: { [key: string]: string } = {};
// onTabChange(event: any) {
//   this.activeTabIndexInterviewDetails = event.index;
//   const selectedTab = this.interviewsTabs?.[event.index];

//   if (selectedTab) {
//     this.selectedTab = selectedTab.title;
//     this.searchitem = '';
//     this.searchInterviewItem = false;
//     this.showAllInterviews = false;
//     this.getMyInterviews(selectedTab.title,this.searchitem);
//   }
// }


//   openFilter(){}

//   // hiringSummary: HiringCard[] = [
//   //   { title: 'Jobs', key: 'activeJobs', buttonText: 'Refer A Candidate', command: () => this.referCandidate() },
//   //   { title: 'Job Requisitions', key: 'jobRequisitions', createButton: true, hasMoreContent: true, buttonText: 'Approve JR', command: () => this.approveJobRequisition() },
//   //   { title: 'Internal Job Postings', key: 'totalCount', buttonText: 'Apply For Internal Job', command: () => this.applyForInternalJob() },
//   //   { title: 'Interviews & Feedback', key: 'interviewCounts', hasMoreContent: true, buttonText: 'Schedule Interview', command: () => this.scheduleInterview() },
//   //   { title: 'Jobs Fair & Events', key: 'jobFairs', hasMoreContent: true, buttonText: 'View Job Fair', command: () => this.viewJobFair() }
//   // ];

//   hiringSummary: HiringCard[] = [
//     { title: 'Jobs', key: 'activeJobs', buttonText: 'Refer A Candidate', command: () => this.referCandidate()},
//     { title: 'Job Requisitions', key: 'jobRequisitions', createButton: true, hasMoreContent: true, buttonText: 'Approve JR', command: () => this.approveJobRequisition(), stats: [    { value: 0, label: 'Open' },    { value: 0, label: 'Pending' },    { value: 0, label: 'Closed' },    { value: 0, label: 'Approved' }  ] },
//     { title: 'Internal Job Postings', key: 'totalCount', buttonText: 'Apply For Internal Job', command: () => this.applyForInternalJob(),},
//     { title: 'Interviews & Feedback', key: 'interviewCounts', hasMoreContent: true, buttonText: 'Interview Schedule', command: () => this.scheduleInterview(), stats: [{ value: 0, label: 'Pending Feedbacks' }] },
//     { title: 'Jobs Fair & Events', key: 'jobFairs', hasMoreContent: true, buttonText: 'View Job Fair', command: () => this.viewJobFair(), stats: [{ value: 0, label: 'Ongoing' }, { value: 0, label: 'Upcoming' }] }
//   ];


//   referCandidate(): void {
//     // console.log('Refer a Candidate clicked');
//     this.router.navigateByUrl('/candidates');
//     // Add logic here
//   }

//   approveJobRequisition(): void {
//     console.log('Approve JR clicked');

//     // Add logic here
//   }

//   applyForInternalJob(): void {
//     // console.log('Apply For Internal Job clicked');
//     this.router.navigateByUrl('/ijp-employee');
//     // Add logic here
//   }

//   scheduleInterview(): void {
//     // console.log('Schedule Interview clicked');
//     this.router.navigateByUrl('/interviews');
//     // Add logic here
//   }

//   viewJobFair(): void {
//     console.log('View Job Fair clicked');
//     // Add logic here
//   }
//   recruiters = [
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     },
//     {
//       name: 'Mishti Verma',
//       email: 'mishtiverma@globalschool.com',
//       phone: '+91 9891989110',
//       location: 'Noida / HR Recruiter',
//       image: 'assets/images/mishti-verma.jpg'
//     }
//   ];

//   searchTaskItem:any;
//   searchInterviewItem:any;
//   searchTemplates(){}
//   clearSearch(){
//     this.searchTaskItem = '';
//   }


//   searchInterviews(event:any): void {
//     console.log(event.target.value);
//     this.searchitem = event.target.value;
//     this.searchInterviewItem = this.searchitem.length > 0;
//     this.searchItemsByTab[this.selectedTab] = this.searchitem;
//     this.getMyInterviews(this.selectedTab,this.searchitem);
//   }
//   currentStatus: string = 'Today';
//   clearInterviewSearch(): void {
//     this.searchitem = '';
//     this.searchItemsByTab[this.selectedTab] = '';
//     this.searchInterviewItem = false;
//     this.getMyInterviews(this.selectedTab,this.searchitem);
//   }
//   showAllInterviews: boolean = false;

// toggleViewAll(): void {
//   this.showAllInterviews = !this.showAllInterviews;
// }

// getDisplayedInterviews(): any[] {
//   return this.activeTab?.content?.data
//     ? this.showAllInterviews
//       ? this.activeTab.content.data
//       : this.activeTab.content.data.slice(0, 5)
//     : [];
// }
// navigateToCareer(path){
//   window.open(path, '_blank');
// }



formattedDate: string;
  searchitem:string='';
  // activeJobs:any;
  totalCount:any;
  todayCount:any;
  upcomingCount:any;
  // newDatatoken:any;
  // userDetails :any ={};
  updateHiringSummary(key: string, data: any) {
    this.hiringSummary = this.hiringSummary.map(card => {
      if (card.key === key) {
        return { ...card, stats: data };
      }
      return card;
    });
  }

  getJobsCount() {
    this.employeeService.getJobsCount().subscribe((res: any) => {
      console.log('Jobs Count:', res);
      this.updateHiringSummary('activeJobs', [{ value: res.activeJobs, label: 'Active' }]);
    });
  }

  getSourceOfHireCount() {
    this.employeeService.getSourceOfHireCount().subscribe((res: any) => {
      console.log('Source of Hire:', res);
      this.updateHiringSummary('totalCount', [{ value: res.ijpCount, label: 'Jobs (Positions)' }]);
    });
  }

  getMyInterviewCount() {
    this.employeeService.getMyInterviewCount().subscribe((res: any) => {
      console.log('Interview Count:', res);
      this.updateHiringSummary('interviewCounts', [
        { value: res.data.todayCount, label: 'Today’s' },
        { value: res.data.upcomingCount, label: 'Upcoming' },
        { value: res.data.completedCount, label: 'Completed ' },
        { value: res.data.pendingCount, label: 'Pending ' },
      ]);
    });
  }

interviewsTabs: any[] = [
  { title: "Today", content: { data: [] } },
  { title: "Upcoming", content: { data: [] } },
  { title: "Completed", content: { data: [] } }
];

get activeTab() {
  return this.interviewsTabs?.[this.activeTabIndexInterviewDetails];
}

getMyInterviews(status: string, searchitem:any): void {
  this.employeeService.getMyInterviews(status,searchitem).subscribe((res: any) => {
    // Check if response data exists
    const hasData = res && Array.isArray(res.data) && res.data.length > 0;

    this.interviewsTabs = [
      { title: "Today", content: status === "Today" && hasData ? res : { data: [] } },
      { title: "Upcoming", content: status === "Upcoming" && hasData ? res : { data: [] } },
      { title: "Completed", content: status === "Completed" && hasData ? res : { data: [] } }
    ];
  });
}

emptyTaskMessage: any;
originalContent: any[] = [];
// allTasksOriginalContent: any[] = []; // Stores all tasks from API
allTasksOriginalContent: any; // Stores all tasks from API
  // todayOriginalContent: any[] = [];
  todayOriginalContent: any;
  getMyActivites(): void {
    // if(this.activitesTabs[this.activeTabIndexTaskDetails].title == 'Today') {
    console.log(this.activitesTabs[this.activeTabIndexTaskDetails].title)
    const myTaskActiveTab = this.activitesTabs[this.activeTabIndexTaskDetails].title;
    // return
    this.employeeService.getMyActivites(myTaskActiveTab).subscribe((res: any) => {
      console.log('🔹 Full API Response:', res);

      // if (res && Array.isArray(res) && res.length > 0) {
      if (res && !res['message']) {
        // Store full data
        // this.allTasksOriginalContent = [...res];
        this.allTasksOriginalContent = res;
        console.log("this.allTasksOriginalContent... ", this.allTasksOriginalContent)
        // Get today's date in 'DD-MM-YYYY' format
        const today = new Date();
        const todayFormatted = today.toLocaleDateString('en-GB').split('/').join('-');

        console.log('🔹 System Date:', todayFormatted);

        console.log("activeTabIndexTaskDetails is... ", this.activeTabIndexTaskDetails)
        if(this.activitesTabs[this.activeTabIndexTaskDetails].title == 'Today') {
          this.activitesTabs[this.activeTabIndexTaskDetails].content = [...this.allTasksOriginalContent.tasks];
          this.activitesTabs[this.activeTabIndexTaskDetails].filteredContent = [...this.allTasksOriginalContent.tasks];
        }
        if(this.activitesTabs[this.activeTabIndexTaskDetails].title == 'All Task') {
          this.activitesTabs[this.activeTabIndexTaskDetails].content = [...this.allTasksOriginalContent.tasks];
          this.activitesTabs[this.activeTabIndexTaskDetails].filteredContent = [...this.allTasksOriginalContent.tasks];
        }
        console.log("activitesTabs..... ", this.activitesTabs)
        // Filter Today's tasks from allTasksOriginalContent
        // this.todayOriginalContent = res.filter(task => task.date === todayFormatted);

        // console.log('✅ Today Tab Interviews:', this.todayOriginalContent);

        // Set initial tab content
        // this.activitesTabs[0].content = [...this.todayOriginalContent]; // Today Tab
        // this.activitesTabs[1].content = [...this.allTasksOriginalContent]; // All Task Tab
        // this.activitesTabs[0].filteredContent = [...this.todayOriginalContent];
        // this.activitesTabs[1].filteredContent = [...this.allTasksOriginalContent];
      } else {
        // emptyTaskMessage
        this.emptyTaskMessage = res['message'];

        this.allTasksOriginalContent = [];
        this.todayOriginalContent = [];

        console.log("activeTabIndexTaskDetails is... ", this.activeTabIndexTaskDetails)
        this.activitesTabs[this.activeTabIndexTaskDetails].content = [];
        this.activitesTabs[this.activeTabIndexTaskDetails].filteredContent = [];

        // this.activitesTabs[0].content = [];
        // this.activitesTabs[1].content = [];
        // this.activitesTabs[0].filteredContent = [];
        // this.activitesTabs[1].filteredContent = [];
      }
    });
  }

  onMyActivitesTabChange(index: number): void {
    this.activeTabIndexTaskDetails = index;
    console.log("activeTabIndexTaskDetails.... ", this.activeTabIndexTaskDetails)
    this.getMyActivites()
    // return
    // if (index === 0) {
    //   // Today Tab
    //   console.log(this.todayOriginalContent, 'todays tab')
    //   this.activitesTabs[0].filteredContent = [...this.todayOriginalContent];
    // } else {
    //   // All Task Tab
    //   this.activitesTabs[1].filteredContent = [...this.allTasksOriginalContent];
    // }

    console.log(`🔄 Switched to ${index === 0 ? 'Today' : 'All Task'} tab.`);
  }

getJobRequisitionsCount(){
  this.employeeService.getJobRequisitionsCount().subscribe((res: any) => {
    console.log('Job Requisitions:', res);
    this.updateHiringSummary('jobRequisitions', [
      { value: res.accepted, label: 'Open' },
      { value: res.pending, label: 'Pending',},
      { value: res.jobClosed, label: 'Closed' },
      { value: res.rejected, label: 'Rejected' } ,
      // { value: res.expired, label: 'Expired' }
    ]);
  });
}

searchTemplates(): void {
  const activeTab = this.activitesTabs[this.activeTabIndexTaskDetails];

  if (!activeTab) return;

  if (!this.searchTaskItem?.trim()) {
    // Restore data from original content based on tab
    activeTab.filteredContent = this.activeTabIndexTaskDetails === 0 ? [...this.todayOriginalContent] : [...this.allTasksOriginalContent];
    return;
  }

  const searchLower = this.searchTaskItem.toLowerCase();
  console.log("allTasksOriginalContent........ ", this.allTasksOriginalContent)
  // return

  // activeTab.filteredContent = (this.activeTabIndexTaskDetails === 0 ? this.todayOriginalContent : this.allTasksOriginalContent)
  activeTab.filteredContent = (this.allTasksOriginalContent.tasks)
    .filter(task => {
      const message = task.message ? task.message.toLowerCase() : '';
      const description = task.description ? task.description.toLowerCase() : '';
      const miniMessage = task.miniMessage ? task.miniMessage.toLowerCase() : '';
      return message.includes(searchLower) || description.includes(searchLower) | miniMessage.includes(searchLower);
    });

  // If no results, show "No interviews found"
  if (activeTab.filteredContent.length === 0) {
    console.log('🚫 No interviews found.');
  }
}




clearSearch(): void {
  this.searchTaskItem = '';
  const activeTab = this.activitesTabs[this.activeTabIndexTaskDetails];
  if (!activeTab) return;
  // activeTab.filteredContent = this.activeTabIndexTaskDetails === 0 ? [...this.todayOriginalContent] : [...this.allTasksOriginalContent];
  activeTab.filteredContent = [...this.allTasksOriginalContent.tasks];

  console.log('🔄 Search cleared. Restored data for:', activeTab.title);
}

selectedTab:any = 'Today';
searchItemsByTab: { [key: string]: string } = {};
onTabChange(event: any) {
  this.activeTabIndexInterviewDetails = event.index;
  const selectedTab = this.interviewsTabs?.[event.index];

  if (selectedTab) {
    this.selectedTab = selectedTab.title;
    this.searchitem = '';
    this.searchInterviewItem = false;
    this.showAllInterviews = false;
    this.getMyInterviews(selectedTab.title,this.searchitem);
  }
}


  openFilter(){}

  // hiringSummary: HiringCard[] = [
  //   { title: 'Jobs', key: 'activeJobs', buttonText: 'Refer A Candidate', command: () => this.referCandidate() },
  //   { title: 'Job Requisitions', key: 'jobRequisitions', createButton: true, hasMoreContent: true, buttonText: 'Approve JR', command: () => this.approveJobRequisition() },
  //   { title: 'Internal Job Postings', key: 'totalCount', buttonText: 'Apply For Internal Job', command: () => this.applyForInternalJob() },
  //   { title: 'Interviews & Feedback', key: 'interviewCounts', hasMoreContent: true, buttonText: 'Schedule Interview', command: () => this.scheduleInterview() },
  //   { title: 'Jobs Fair & Events', key: 'jobFairs', hasMoreContent: true, buttonText: 'View Job Fair', command: () => this.viewJobFair() }
  // ];
  showTooltip:boolean = false;
  hiringSummary: HiringCard[] = [
    { title: 'Jobs', key: 'activeJobs', buttonText: 'Refer A Candidate', command: () => this.referCandidate()},
    { title: 'Job Requisitions', key: 'jobRequisitions', createButton: true, hasMoreContent: true, buttonText: 'Approve JR', command: () => this.approveJobRequisition() },
    { title: 'Internal Job Postings', key: 'totalCount', buttonText: 'Apply For Internal Job', command: () => this.applyForInternalJob(),},
    { title: 'Interviews & Feedback', key: 'interviewCounts', hasMoreContent: true, buttonText: 'Interview Schedule', command: () => this.scheduleInterview(),   },
    { title: 'Jobs Fair & Events', key: 'jobFairs', hasMoreContent: true, buttonText: 'View Job Fair', command: () => this.viewJobFair(), stats: [{ value: 0, label: 'Ongoing' }, { value: 0, label: 'Upcoming' }] }
  ];


  referCandidate(): void {
    // console.log('Refer a Candidate clicked');
    this.router.navigateByUrl('/candidates');
    // Add logic here
  }

  approveJobRequisition(): void {
    console.log('Approve JR clicked');

    // Add logic here
  }

  applyForInternalJob(): void {
    // console.log('Apply For Internal Job clicked');
    this.router.navigateByUrl('/ijp-employee');
    // Add logic here
  }

  scheduleInterview(): void {
    // console.log('Schedule Interview clicked');
    this.router.navigateByUrl('/interviews');
    // Add logic here
  }

  viewJobFair(): void {
    console.log('View Job Fair clicked');
    // Add logic here
  }

  routeToInterviews(message:string){
    if (message === "Job - Waiting For Approval") {
      this.router.navigateByUrl('/jobs');
    } else if (message === "Interview Scheduled") {
      this.router.navigateByUrl('/interviews');
    }else if (message === "Interview ReSchedule"){
      this.router.navigateByUrl('/interviews');
    }
  }
  // recruiters = [
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   },
  //   {
  //     name: 'Mishti Verma',
  //     email: 'mishtiverma@globalschool.com',
  //     phone: '+91 9891989110',
  //     location: 'Noida / HR Recruiter',
  //     image: 'assets/images/mishti-verma.jpg'
  //   }
  // ];

  searchTaskItem:any;
  searchInterviewItem:any;
  // searchTemplates(){}
  // clearSearch(){
  //   this.searchTaskItem = '';
  // }


  searchInterviews(event:any): void {
    console.log(event.target.value);
    this.searchitem = event.target.value;
    this.searchInterviewItem = this.searchitem.length > 0;
    this.searchItemsByTab[this.selectedTab] = this.searchitem;
    this.getMyInterviews(this.selectedTab,this.searchitem);
  }
  currentStatus: string = 'Today';
  clearInterviewSearch(): void {
    this.searchitem = '';
    this.searchItemsByTab[this.selectedTab] = '';
    this.searchInterviewItem = false;
    this.getMyInterviews(this.selectedTab,this.searchitem);
  }
  showAllInterviews: boolean = false;

toggleViewAll(): void {
  this.showAllInterviews = !this.showAllInterviews;
}

getDisplayedInterviews(): any[] {
  return this.activeTab?.content?.data
    ? this.showAllInterviews
      ? this.activeTab.content.data
      : this.activeTab.content.data.slice(0, 5)
    : [];
}
navigateToCareer(path){
  window.open(path, '_blank');
}

getFormattedTime(time: string): string {
  if (!time) return '';
  return time.split('-').map(this.to12HourFormat).join(' - ');
}

private to12HourFormat(time: string): string {
  let [hours, minutes] = time.split(':').map(Number);
  const period = hours < 12 ? 'AM' : 'PM';

  hours = hours % 12 || 12;

  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
}


formatHour(hour: string): string {
  const [h, m] = hour.split(':');
  const hourNum = +h > 12 ? +h - 12 : +h;
  return `${hourNum}:${m}`;
}

  trfRedirect(){
    this.router.navigateByUrl('/trf');
  }

  recruiterName:any;
  recruiters: any[] = [];
  campuses: string[] = [];
  role: string = '';
  getCampusRecruiters(){
    this.employeeService.getCampusRecruiters().subscribe((res: any) => {
      console.log('campus recruiters:', res);
      if (res) {
        // Extract recruiters data
        this.recruiters = res.data.map((recruiter: any) => ({
          recruiterName: recruiter.userFullName,
          email: recruiter.userName, // Assuming email is in userName
          phone: recruiter.phoneCode + ' ' + recruiter.phoneNo,
          location: recruiter.location || 'N/A', // If location is missing, set default
        }));

        // Extract campus list
        this.campuses = res.campus || [];

        // Extract role
        this.role = res.role || 'N/A';
      }
    });
  }
}
